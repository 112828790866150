<template>

  <loader :active="loading" message="Please wait..." />

  <div class="admin-header-bottom">
    <nav class="admin-breadcrumb" aria-label="breadcrumb">
      <ol class="breadcrumb">
        <li class="breadcrumb-item">
          <router-link to="/">Dashboard</router-link>
        </li>
        <li class="breadcrumb-item active" aria-current="page">My Project</li>
      </ol>
    </nav>
    <h1 class="page-title">My Project</h1>
    <!-- <div class="header-btn-cont">
      <a class="admin-common-btn" style="cursor: pointer;" data-bs-toggle="modal" data-bs-target="#modal-add-project"
        @click="addProject">Add
        Project</a>
    </div> -->
  </div>

  <div class="admin-body">
    <div class="admin-body-box">
      <div class="admin-body-bottom">
        <div class="table-top">
          <div class="header-top-btn-cont">
            <a class="admin-common-btn" style="cursor: pointer;" data-bs-toggle="modal" data-bs-target="#modal-add-project"
              @click="addProject">Add
              Project</a>
          </div>
          <input class="table-search-input" placeholder="Search Projects.." v-model="searchQueryProjects" />
          <!-- <div class="btn-cont"> -->
            <!-- <a class="admin-common-btn btn-green btn-export-to-excel" @click="exportToExcel()"><img
                class="icon-down-arrow" src="images/icon-excel.svg" alt="Excel">Export to
              Excel</a> -->
          <!-- </div> -->
        </div>
        <div class="data-table-cont">
          <div id="example_wrapper" class="dataTables_wrapper">
            <div class="row dt-row">
              <div class="table-responsive table-my-project">
                <table id="example" class="data-table table table-striped table-border-none">
                  <thead class="sticky-top">
                    <tr>
                      <th class="no-wrap">Project Name</th>
                      <th class="no-wrap">Domain</th>
                      <th class="no-wrap">Location</th>
                      <th class="no-wrap">Keywords Count</th>
                      <th class="no-wrap">Date Created</th>
                      <th class="no-wrap">Search Engine</th>
                      <th class="no-wrap">Run Time</th>
                      <th class="no-wrap">Last Run</th>
                      <th class="no-wrap">Actions</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="(project, index) in projects" :key="index" @mouseover="highlightRow"
                      @mouseout="unhighlightRow">
                      <td class="no-wrap"><router-link :to="'/view-project/' + project.id">{{ project.projectName || '-'
                          }}</router-link></td>
                      <td class="no-wrap">{{ project.domain || '-' }}</td>
                      <td class="no-wrap">📍{{ project.location || '-' }}</td>
                      <td class="no-wrap">{{ project.keyword_count || '-' }}</td>
                      <td class="no-wrap responsive-wrap max-width110-mobile">{{ project.date_created || '-' }}</td>
                      <td class="no-wrap">{{ project.searchName || '-' }}</td>
                      <td class="no-wrap">
                        {{ project.duration === 'onceInDay' ? 'Daily' : (durationOptions.find(option => option.value ===
    project.duration)?.name || '-') }}
                      </td>
                      <td class="no-wrap responsive-wrap max-width110-mobile">{{ project.last_executed_at || '-' }}</td>
                      <td class="no-wrap">
                        <div class="action-icon-cont">
                          <router-link :to="'/view-project/' + project.id" class="action-icon icon-view">
                            <img class="icon-img" src="images/icon-view.svg" alt="View">
                          </router-link>
                          <a class="action-icon icon-edit-project" data-bs-toggle="modal"
                            data-bs-target="#modal-add-project" @click="editProject(project)">
                            <img class="icon-img" src="images/icon-edit-project.svg" alt="Edit Project">
                          </a>
                          <a class="action-icon icon-delete" href="#" @click="confirmDelete(project.id)">
                            <img class="icon-img" src="images/icon-delete.svg" alt="Delete">
                          </a>
                        </div>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <!-- <div class="pagination-container">
                <PaginationComponent :currentPage="currentPageProjects" :lastPage="lastPageProjects"
                  :fetchPage="fetchData" />
              </div> -->
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="pagination-container">
    <PaginationComponent :currentPage="currentPageProjects" :lastPage="lastPageProjects" :fetchPage="fetchData" />
  </div>

  <div class="copyright-cont">
    <p class="copyright-text">&copy; 2024 Plus Promotions UK LTD | Registered in UK, Company Number 13871077, VAT Number
      438499640</p>
  </div>

  <!-- MODAL - ADD PROJECT - START -->
  <div id="modal-add-project" class="common-modal-cont modal fade" tabindex="-1" aria-hidden="true"
    v-show="projectModel">
    <div class="modal-dialog modal-924 modal-dialog-centered">
      <div class="modal-content">
        <form action="">
          <div class="modal-header">
            <h5 class="modal-title">
              <span class="mod-title">Add Project</span>
              <!-- <div class="info-cont tooltip-trigger">
                <img class="info-icon" src="images/icon-info-circle.svg" alt="Info">
                <div class="custom-tooltip">
                  <div class="tooltip-cont">
                    <img class="icon" src="images/icon-info-circle-white.svg" alt="Info">
                    <span class="text">Add Project</span>
                  </div>
                </div>
              </div> -->
            </h5>
            <!-- <button class="btn-close" type="button" data-bs-dismiss="modal" aria-label="Close"></button> -->
            <a class="btn-close" data-bs-dismiss="modal" aria-label="Close">
              <img src="images/icon-popup-close.svg" alt="Close">
            </a>
          </div>
          <div class="modal-body">
            <div class="common-nav-tab-cont">
              <ul class="nav nav-tabs" id="myTab" role="tablist"
                :style="{ 'pointer-events': isEdit ? 'none' : 'auto' }">
                <li class="nav-item" role="presentation">
                  <button class="nav-link" :class="{ 'active': activeTab === 0 }" @click="changeTab(0)" type="button"
                    role="tab" aria-controls="project-information" aria-selected="true">Project Information</button>
                </li>
                <li class="nav-item" role="presentation">
                  <button class="nav-link" :class="{ 'active': activeTab === 1 }" @click="changeTab(1)" type="button"
                    role="tab" aria-controls="project-details" aria-selected="false">Project Details</button>
                </li>
                <li class="nav-item" role="presentation">
                  <button class="nav-link" :class="{ 'active': activeTab === 2 }" @click="changeTab(2)" type="button"
                    role="tab" aria-controls="project-location" aria-selected="false">Project Location</button>
                </li>
                <li class="nav-item" role="presentation">
                  <button class="nav-link" :class="{ 'active': activeTab === 3 }" @click="changeTab(3)" type="button"
                    role="tab" aria-controls="run-time" aria-selected="false">Run Time</button>
                </li>
              </ul>
              <div class="tab-content" id="myTabContent">
                <div class="tab-pane fade" :class="{ 'show active': activeTab === 0 }" id="project-information"
                  role="tabpanel" aria-labelledby="project-information-tab">
                  <div class="modal-form-cont check-serp-form-cont">
                    <div class="row">
                      <div class="form-col col-xs-12 col-md-6 col-lg-6">
                        <div class="form-group">
                          <label>Project Name</label>
                          <input class="form-control" type="text" v-model="form.projectName"
                            placeholder="Enter Project Name" :class="{ 'is-invalid': projectNameValidation }">
                          <div v-if="projectNameValidation" class="invalid-feedback">Please enter a ProjectName.
                          </div>
                        </div>
                      </div>
                      <div class="form-col col-xs-12 col-md-6 col-lg-6">
                        <div class="form-group">
                          <label>Search Engine</label>
                          <select class="form-control" v-model="form.searchEngine" :disabled="userData?.type == 1">
                            <option>Select</option>
                            <option v-for="engine in searchEngine" :key="engine.id" :value="engine.id">{{ engine.name }}
                            </option>
                          </select>
                          <span class="required-alert" v-show="userData?.type == 1">Upgrade to view more option</span>
                        </div>
                      </div>
                      <div class="form-col col-xs-12 col-md-6 col-lg-6">
                        <div class="form-group">
                          <label>Device Type</label>
                          <select class="form-control" v-model="form.deviceType" :disabled="userData?.type == 1">
                            <option>Select</option>
                            <option v-for="device in deviceType" :key="device.id" :value="device.id">{{ device.name }}
                            </option>
                          </select>
                          <span class="required-alert" v-show="userData?.type == 1">Upgrade to view more option</span>
                        </div>
                      </div>
                      <div class="form-col col-xs-12 col-md-6 col-lg-6">
                        <div class="form-group">
                          <label>Languages</label>
                          <select class="form-control" v-model="form.language" :disabled="userData?.type == 1">
                            <option>Select Language</option>
                            <option v-for="language in languages" :key="language.code" :value="language.code">
                              {{ language.name }}
                            </option>
                          </select>
                          <span class="required-alert" v-show="userData?.type == 1">Upgrade to view more option</span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="tab-pane fade" :class="{ 'show active': activeTab === 1 }" id="project-details"
                  role="tabpanel" aria-labelledby="project-details-tab">
                  <div class="modal-form-cont check-serp-form-cont">
                    <div class="row">
                      <div class="form-col col-xs-12 col-md-12 col-lg-12">
                        <div class="form-group">
                          <label>Your Domain/URL</label>
                          <InputText v-model="form.domainUrl" :class="{ 'is-invalid': domainValidation }" id="domainUrl"
                            type="text" class="form-control" placeholder="Enter Your Domain" />
                          <div v-if="domainValidation" class="invalid-feedback">{{ validationMessage }}</div>
                        </div>
                      </div>
                      <div class="form-col col-xs-12 col-md-6 col-lg-6" v-show="isEdit">
                        <div class="form-group">
                          <label>Keywords</label>
                          <textarea class="form-control" v-model="form.keywords"
                            placeholder="Enter keywords, separated by Newline"></textarea>
                        </div>
                      </div>
                      <div class="form-col col-xs-12 col-md-6 col-lg-6" v-show="isEdit">
                        <div class="form-group">
                          <label>Competitors</label>
                          <textarea class="form-control" v-model="form.competitors" :disabled="userData?.type == 1"
                            placeholder="Enter Competitors, separated by Newline"></textarea>
                          <span class="required-alert" v-show="userData?.type == 1">Upgrade to view more option</span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="tab-pane fade" :class="{ 'show active': activeTab === 2 }" id="project-location"
                  role="tabpanel" aria-labelledby="project-location-tab">
                  <div class="modal-form-cont check-serp-form-cont">
                    <div class="row">
                      <div class="form-col col-xs-12 col-md-6 col-lg-6">
                        <div class="form-group">
                          <label>Country</label>
                          <select class="form-control" v-model="form.country" @change="onCountryChange"
                            :class="{ 'is-invalid': countryValidation }">
                            <option>Select a Country</option>
                            <option v-for="country in regionList" :key="country.country_iso_code" :value="country">
                              {{ country.location_name }}
                            </option>
                          </select>
                          <div v-if="countryValidation" class="invalid-feedback">Please select Country.</div>
                        </div>
                      </div>
                      <div class="form-col col-xs-12 col-md-6 col-lg-6">
                        <div class="form-group">
                          <label>Search Location</label>
                          <div class="autocomplete">
                            <input v-model="searchQuery" @input="onChange" type="text"
                              placeholder="Enter State (e.g., Florida) and City (e.g., Miami)..."
                              class="form-control" />

                            <ul v-show="showResults" class="autocomplete-results">
                              <li v-for="(result, index) in searchResults" :key="index" class="autocomplete-result"
                                @click="selectResult(result)">
                                {{ result.location_name }}
                              </li>
                              <li v-if="searchResults?.length === 0 && searchQuery.trim() !== ''"
                                class="autocomplete-no-results">
                                No results found
                              </li>
                            </ul>

                          </div>

                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="tab-pane fade" :class="{ 'show active': activeTab === 3 }" id="run-time" role="tabpanel"
                  aria-labelledby="run-time-tab">
                  <div class="modal-form-cont check-serp-form-cont">
                    <div class="row">

                      <div class="form-col col-xs-12 col-md-6 col-lg-6">
                        <div class="form-group">
                          <div>
                            <label>Run Once a Day(Recommended)?</label>
                            <input type="checkbox" v-model="form.isDailyChecked" @click="nullDuration"
                              style="margin-top: 5px; margin-left: 10px" :disabled="userData?.type == 1">

                          </div>
                          <label>Duration</label>
                          <select class="form-control" v-model="form.duration" @change="falseIsDailyChecked"
                            :disabled="userData?.type == 1">
                            <option v-for="(duration, index) in durationOptions" :key="index" :value="duration.value">
                              {{ duration.name }}
                            </option>
                          </select>
                          <span class="required-alert" v-show="userData?.type == 1">Upgrade to view more option</span>

                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="modal-footer">
            <button class="admin-common-btn btn-bordered btn btn-secondary btn-previous" @click="previousTab"
              :disabled="activeTab === 0" type="button">Previous</button>
            <button class="admin-common-btn btn btn-primary btn-next" @click="closeModalOnAction"
              :disabled="isLoading || !isCurrentTabValid" type="button">
              <template v-if="isLoading">Loading...</template>
              <template v-else>{{ isUpdateBtn ? 'Update' : (activeTab === 3 ? 'Submit' : 'Next') }}</template>
            </button>
          </div>

        </form>
      </div>
    </div>
  </div>
  <!-- MODAL - ADD PROJECT - END -->

</template>

<script setup>
import { ref, onMounted, computed, watch, reactive } from 'vue';
import CountryService from '@/service/CountryService';
import SerpService from '@/service/SerpService';
import PaginationComponent from '@/components/PaginationComponent.vue';
import { toast } from 'vue3-toastify';
import 'vue3-toastify/dist/index.css';
import axiosIns from '@/axios';
import EventBus from '@/EventBus';
import loader from '../Loader.vue';

const regionList = ref(null);
const cityStateData = ref([]);

const countryService = new CountryService();
const serpService = new SerpService();
const loading = ref(false)
const form = ref({
  projectName: null,
  language: 'en',
  searchEngine: 1,
  deviceType: 1,
  domainUrl: null,
  keywords: '',
  competitors: '',
  country: null,
  state_city: '',
  duration: 'onceInDay',
  isDailyChecked: true,
});
const domainValidation = computed(() => {
  const domainRegex = /^[a-zA-Z0-9-]+(\.[a-zA-Z]{2,})+$/;
  return !domainRegex.test(form.value.domainUrl);
});

const validationMessage = computed(() => {
  return domainValidation.value ? "Please enter a valid Domain (e.g., example.com)." : "";
});
const pageSize = 20;
const projectId = ref('');
const activeTab = ref(0);
const searchQuery = ref('');
const submitted = ref(false);
const currentPage = ref(1);
const projects = ref([])
const currentPageProjects = ref(1)
const lastPageProjects = ref(1)
const searchQueryProjects = ref('')
const isLoading = ref(false)

const projectNameValidation = computed(() => submitted.value && !form.value.projectName);
// const domainValidation = computed(() => submitted.value && !form.value.domainUrl);
const countryValidation = computed(() => submitted.value && !form.value.country);

const languages = [
  { code: 'af', name: 'Afrikaans' },
  { code: 'sq', name: 'Albanian' },
  { code: 'am', name: 'Amharic' },
  { code: 'ar', name: 'Arabic' },
  { code: 'hy', name: 'Armenian' },
  { code: 'az', name: 'Azerbaijani' },
  { code: 'eu', name: 'Basque' },
  { code: 'be', name: 'Belarusian' },
  { code: 'bn', name: 'Bengali' },
  { code: 'bs', name: 'Bosnian' },
  { code: 'bg', name: 'Bulgarian' },
  { code: 'ca', name: 'Catalan' },
  { code: 'ceb', name: 'Cebuano' },
  { code: 'ny', name: 'Chichewa' },
  { code: 'zh', name: 'Chinese (Simplified)' },
  { code: 'zh-TW', name: 'Chinese (Traditional)' },
  { code: 'co', name: 'Corsican' },
  { code: 'hr', name: 'Croatian' },
  { code: 'cs', name: 'Czech' },
  { code: 'da', name: 'Danish' },
  { code: 'nl', name: 'Dutch' },
  { code: 'en', name: 'English' },
  { code: 'eo', name: 'Esperanto' },
  { code: 'et', name: 'Estonian' },
  { code: 'tl', name: 'Filipino' },
  { code: 'fi', name: 'Finnish' },
  { code: 'fr', name: 'French' },
  { code: 'fy', name: 'Frisian' },
  { code: 'gl', name: 'Galician' },
  { code: 'ka', name: 'Georgian' },
  { code: 'de', name: 'German' },
  { code: 'el', name: 'Greek' },
  { code: 'gu', name: 'Gujarati' },
  { code: 'ht', name: 'Haitian Creole' },
  { code: 'ha', name: 'Hausa' },
  { code: 'haw', name: 'Hawaiian' },
  { code: 'iw', name: 'Hebrew' },
  { code: 'hi', name: 'Hindi' },
  { code: 'hmn', name: 'Hmong' },
  { code: 'hu', name: 'Hungarian' },
  { code: 'is', name: 'Icelandic' },
  { code: 'ig', name: 'Igbo' },
  { code: 'id', name: 'Indonesian' },
  { code: 'ga', name: 'Irish' },
  { code: 'it', name: 'Italian' },
  { code: 'ja', name: 'Japanese' },
  { code: 'jw', name: 'Javanese' },
  { code: 'kn', name: 'Kannada' },
  { code: 'kk', name: 'Kazakh' },
  { code: 'km', name: 'Khmer' },
  { code: 'rw', name: 'Kinyarwanda' },
  { code: 'ko', name: 'Korean' },
  { code: 'ku', name: 'Kurdish (Kurmanji)' },
  { code: 'ky', name: 'Kyrgyz' },
  { code: 'lo', name: 'Lao' },
  { code: 'la', name: 'Latin' },
  { code: 'lv', name: 'Latvian' },
  { code: 'lt', name: 'Lithuanian' },
  { code: 'lb', name: 'Luxembourgish' },
  { code: 'mk', name: 'Macedonian' },
  { code: 'mg', name: 'Malagasy' },
  { code: 'ms', name: 'Malay' },
  { code: 'ml', name: 'Malayalam' },
  { code: 'mt', name: 'Maltese' },
  { code: 'mi', name: 'Maori' },
  { code: 'mr', name: 'Marathi' },
  { code: 'mn', name: 'Mongolian' },
  { code: 'my', name: 'Myanmar (Burmese)' },
  { code: 'ne', name: 'Nepali' },
  { code: 'no', name: 'Norwegian' },
  { code: 'or', name: 'Odia (Oriya)' },
  { code: 'ps', name: 'Pashto' },
  { code: 'fa', name: 'Persian' },
  { code: 'pl', name: 'Polish' },
  { code: 'pt', name: 'Portuguese' },
  { code: 'pa', name: 'Punjabi' },
  { code: 'ro', name: 'Romanian' },
  { code: 'ru', name: 'Russian' },
  { code: 'sm', name: 'Samoan' },
  { code: 'gd', name: 'Scots Gaelic' },
  { code: 'sr', name: 'Serbian' },
  { code: 'st', name: 'Sesotho' },
  { code: 'sn', name: 'Shona' },
  { code: 'sd', name: 'Sindhi' },
  { code: 'si', name: 'Sinhala' },
  { code: 'sk', name: 'Slovak' },
  { code: 'sl', name: 'Slovenian' },
  { code: 'so', name: 'Somali' },
  { code: 'es', name: 'Spanish' },
  { code: 'su', name: 'Sundanese' },
  { code: 'sw', name: 'Swahili' },
  { code: 'sv', name: 'Swedish' },
  { code: 'tg', name: 'Tajik' },
  { code: 'ta', name: 'Tamil' },
  { code: 'te', name: 'Telugu' },
  { code: 'th', name: 'Thai' },
  { code: 'tr', name: 'Turkish' },
  { code: 'uk', name: 'Ukrainian' },
  { code: 'ur', name: 'Urdu' },
  { code: 'ug', name: 'Uyghur' },
  { code: 'uz', name: 'Uzbek' },
  { code: 'vi', name: 'Vietnamese' },
  { code: 'cy', name: 'Welsh' },
  { code: 'xh', name: 'Xhosa' },
  { code: 'yi', name: 'Yiddish' },
  { code: 'yo', name: 'Yoruba' },
  { code: 'zu', name: 'Zulu' }
];


const searchEngine = ref([
  { id: 1, name: 'Google' },
  { id: 2, name: 'Yahoo' },
  { id: 3, name: 'Bing' }
]);

const deviceType = ref([
  { id: 1, name: 'Desktop' },
  { id: 2, name: 'Tablet' },
  { id: 3, name: 'Mobile' }
]);

const durationOptions = [
  { value: 'onceInWeek', name: 'Once a Week' },
  { value: 'onceInTwoWeek', name: 'Once Bi-Weekly' },
  { value: 'onceInMonth', name: 'Once a Month' },
  { value: 'never', name: 'Never' },
];

const isFirstTabValid = computed(() => {
  return form.value.projectName !== null;
});

const falseIsDailyChecked = () => {
  form.value.isDailyChecked = false
}

const nullDuration = () => {
  form.value.duration = null
}

const isSecondTabValid = computed(() => {
  return form.value.domainUrl !== null &&
    form.value.keywords !== null &&
    form.value.competitors !== null && domainValidation.value == false;
});

const isThirdTabValid = computed(() => {
  return form.value.country !== null;
});

const isCurrentTabValid = computed(() => {
  switch (activeTab.value) {
    case 0:
      return isFirstTabValid.value;
    case 1:
      return isSecondTabValid.value;
    case 2:
      return isThirdTabValid.value;
    default:
      return true;
  }
});

const showResults = ref(false);

const searchResults = computed(() => {
  if (!searchQuery.value.trim()) {
    return [];
  } else {
    return filteredLocations.value.filter(location =>
      location.location_name.toLowerCase().includes(searchQuery.value.trim().toLowerCase())
    );
  }
});

const onChange = () => {
  showResults.value = true;
};

const selectResult = (result) => {
  searchQuery.value = result.location_name;
  showResults.value = false;
  form.value.state_city = result;
};
const isEdit = ref(true);

const onCountryChange = async () => {
  currentPage.value = 1; // Reset current page
  await fetchCityStateData();
};

const fetchCityStateData = async () => {
  try {
    const response = await countryService.getCityAndState(form.value.country.country_iso_code, currentPage.value, pageSize);
    if (currentPage.value === 1) {
      cityStateData.value = response;
    } else {
      cityStateData.value = [...cityStateData.value, ...response];
    }
  } catch (error) {
    console.error('Error fetching city data:', error);
  }
};

const visibleLocations = computed(() => {
  if (cityStateData.value) {
    return cityStateData.value.slice(0, currentPage.value * pageSize);
  } else {
    return [];
  }
});

const filteredLocations = computed(() => {
  if (!searchQuery.value) {
    return visibleLocations.value;
  } else {
    const query = searchQuery.value.toLowerCase();
    return cityStateData.value.filter(location => {
      return location.location_name.toLowerCase().includes(query);
    });
  }
});

const changeTab = (index) => {
  activeTab.value = index;
};

const nextTab = () => {
  if (activeTab.value < 3) {
    activeTab.value++;
  }
};

const previousTab = () => {
  if (activeTab.value > 0) {
    activeTab.value--;
  }
};
const userData = ref(null)

const isUpdateBtn = ref(false)
const projectModel = ref(false)

async function closeModalOnAction() {
  if (isUpdateBtn.value) {
    await updateProject();
  } else if (activeTab.value === 3) {
    await submitForm();
  } else {
    nextTab();
    return; // Don't close the modal if it's just navigating to the next tab
  }

  // Close modal

}
const editProject = async (project) => {
  projectId.value = project.id
  isEdit.value = false
  const data = project
  //const competitors = JSON.parse(data.competitors).join('\n');

  //const keywords = data.keywords.map(keyword => keyword).join('\n');

  form.value = {
    id: data.id,
    projectName: data.projectName,
    searchEngine: data.searchEngine,
    deviceType: data.deviceType,
    domainUrl: data.domainUrl,
    duration: data.duration,
    country: data.country,
    location_name: data.location_name,
    option_location: data.option_location,
    // competitors: competitors,
    // keywords: keywords,
    created_at: data.created_at,
    updated_at: data.updated_at,
    language: data.language,
    last_executed_at: data.last_executed_at,
    isDailyChecked: data.isDailyChecked,

  };

  isUpdateBtn.value = true;
}

const updateProject = async () => {
  isUpdateBtn.value = false;

  const formData = {
    random_id: generateRandomId(),
    projectName: form.value.projectName,
    searchEngine: form.value.searchEngine,
    deviceType: form.value.deviceType,
    language: form.value.language,
    domainUrl: form.value.domainUrl,
    country: form.value.country,
    state_city: form.value.state_city ? form.value.state_city.location_code : null,
    location_name: form.value.state_city ? form.value.state_city.location_name : null,
    option_location: form.value.country ? form.value.country.location_name : null,
    duration: form.value.isDailyChecked == true ? 'onceInDay' : form.value.duration,
    isDailyChecked: form.value.isDailyChecked,
  };

  try {
    const data = await serpService.UpdateProject(projectId.value, formData);

    toast.success('Project Updated Successfully', {
      position: toast.POSITION.TOP_RIGHT,
      duration: 6000 // 3000 milliseconds timeout
    });
    const modal = document.getElementById('modal-add-project');
    if (modal) {
      modal.style.display = 'none';
      const modalBackdrop = document.getElementsByClassName('modal-backdrop')[0];
      if (modalBackdrop) {
        modalBackdrop.remove();
      }
    }
    setTimeout(() => {
      fetchData(currentPageProjects.value);
    }, 1000);
  } catch (error) {
    // Handle errors
    toast.error('Error updating Project', {
      position: toast.POSITION.TOP_RIGHT,
      duration: 6000 // 3000 milliseconds timeout
    });
    console.error('Error updating Project:', error);
  }
};

const addProject = () => {
  isEdit.value = true;
  form.value.projectName = null
  form.value.domainUrl = null
  form.value.state_city = null
  form.value.country = null
  form.value.location_name = null
  form.value.option_location = null
  form.value.duration = 'onceInDay'
  form.value.isDailyChecked = true
  form.value.searchEngine = 1
  form.value.language = 'en';
  form.value.deviceType = 1;
  isUpdateBtn.value = false;
  isLoading.value = false;
}


const submitForm = () => {
  submitted.value = true;

  if (domainValidation.value || projectNameValidation.value || countryValidation.value) {
    return;
  }

  isLoading.value = true;

  const formData = {
    random_id: generateRandomId(),
    projectName: form.value.projectName,
    searchEngine: form.value.searchEngine,
    deviceType: form.value.deviceType,
    language: form.value.language,
    domainUrl: form.value.domainUrl,
    keyword: form.value.keywords ? form.value.keywords.split('\n').filter(keyword => keyword.trim() !== '') : [],
    competitor: form.value.competitors ? form.value.competitors.split('\n').filter(competitor => competitor.trim() !== '') : [],
    country: form.value.country ? Number(form.value.country.location_code) : null,
    state_city: form.value.state_city ? form.value.state_city.location_code : null,
    location_name: form.value.state_city ? form.value.state_city.location_name : null,
    option_location: form.value.country ? form.value.country.location_name : null,
    duration: form.value.isDailyChecked == true ? 'onceInDay' : form.value.duration,
    isDailyChecked: form.value.isDailyChecked,
  };


  if (formData.keyword.length > 100) {
    // Display a message indicating that the limit is exceeded
    toast.error(`Serpplus does not support more than 100 keywords. Please reduce the number of keywords.`, {
      position: toast.POSITION.TOP_RIGHT,
      duration: 6000 // 6000 milliseconds timeout
    });
    isLoading.value = false;
    return;
  }

  if (userData.value.type == 1 && formData.keyword.length > 20) {
    // Display a message tailored for trial users
    toast.error(`As a trial user, Serpplus supports a maximum of 20 keywords. Please reduce the number of keywords.`, {
      position: toast.POSITION.TOP_RIGHT,
      duration: 6000 // 6000 milliseconds timeout
    });
    isLoading.value = false;
    return;
  }

  serpService
    .addProject(formData)
    .then(() => {
      toast.success('Project Created Successfully', {
        position: toast.POSITION.TOP_RIGHT,
        duration: 6000 // 3000 milliseconds timeout
      });
      EventBus.$emit('loadCredits', true);

      const modal = document.getElementById('modal-add-project');
      if (modal) {
        modal.style.display = 'none';
        const modalBackdrop = document.getElementsByClassName('modal-backdrop')[0];
        if (modalBackdrop) {
          modalBackdrop.remove();
        }
      }
      fetchData(currentPageProjects.value)
    })
    .catch((error) => {
      let errorMessage = 'Error creating SERP. Please try again later.';
      if (error.response && error.response.status === 404) {
        errorMessage = 'Insufficient credits. Please add more credits to continue.';
      } else if (error.response && error.response.status === 403) {
        errorMessage = 'No remaining projects allowed for this user. Please upgrade your plan to get more projects.';
      }
      toast.error(errorMessage, {
        position: toast.POSITION.TOP_RIGHT,
        duration: 6000 // 3000 milliseconds timeout
      });
      console.error('Error creating SERP:', error);
    });

};


const generateRandomId = () => {
  const randomString = Math.random().toString(36).substring(2, 8);

  const randomNumber1 = Math.floor(Math.random() * 10);
  const randomNumber2 = Math.floor(Math.random() * 10);

  const randomId = randomString + randomNumber1 + randomNumber2;

  return randomId;
};

const confirmDelete = async (projectId) => {
  if (confirm('Are you sure you want to delete this project?')) {
    try {
      await axiosIns.delete(`/projects/${projectId}`);
      projects.value = projects.value.filter((p) => p.id !== projectId);
      toast.success('Project Deleted', {
        position: toast.POSITION.TOP_RIGHT,
        duration: 6000 // 3000 milliseconds timeout
      });

    } catch (error) {
      toast.error('Error Deleting Project', {
        position: toast.POSITION.TOP_RIGHT,
        duration: 6000 // 3000 milliseconds timeout
      });
      console.error('Error Deleting Project:', error);
    }
  }
};
const fetchData = async (page) => {
  loading.value = true
  try {
    const data = await serpService.listProjects(page, searchQueryProjects);
    currentPageProjects.value = Number(data.data.pagination.current_page)
    lastPageProjects.value = Number(data.data.pagination.last_page)
    loading.value = false
    data.data.projects.forEach(project => {
      const datetime = new Date(project.date_created);
      const formattedDate = datetime.toLocaleDateString('en-GB', {
        day: '2-digit',
        month: '2-digit',
        year: 'numeric'
      }).replace(/\//g, '-');
      const time = datetime.toLocaleTimeString('en-GB', {
        hour: '2-digit',
        minute: '2-digit',
        second: '2-digit'
      });
      project.date_created = `${formattedDate} ${time}`;
      project.isDailyChecked = project.isDailyChecked == 1 ? true : false;
    });

    projects.value = data.data.projects;

    toast.success('Your data has been successfully loaded', {
      position: toast.POSITION.TOP_RIGHT,
      duration: 6000 // 3000 milliseconds timeout
    });
  } catch (error) {
    // Handle any errors here
    console.error('Error fetching data:', error);
  }
};



onMounted(() => {
  const data = localStorage.getItem('userData');
  userData.value = JSON.parse(data);

  countryService.getCountries().then((data) => {
    regionList.value = data.sort((a, b) => a.location_name.localeCompare(b.location_name));
  });
  fetchData(currentPageProjects.value)
});

watch(searchQueryProjects, (newVal, oldVal) => {
  if (newVal !== oldVal) {
    currentPageProjects.value = 1; // Reset current page when search query changes
    fetchData(currentPageProjects.value)
  }
});


</script>


<style scoped>
.modal-backdrop {
  display: none;
}

.table-row:hover {
  background-color: #f2f2f2;
}

.table-cell {
  padding: 10px;
  text-align: center;
}

.action-icons {
  display: flex;
  justify-content: center;
  align-items: center;
}

.autocomplete {
  position: relative;
}

.autocomplete-results {
  position: absolute;
  top: 100%;
  left: 0;
  width: 100%;
  list-style: none;
  margin: 0;
  padding: 0;
  border: 1px solid #ccc;
  border-top: none;
  border-radius: 4px;
  background-color: #fff;
}

.autocomplete-result {
  cursor: pointer;
}

.autocomplete-result:hover {
  background-color: #f0f0f0;
}

.autocomplete-no-results {
  padding: 8px;
  color: #999;
}

.icon-button {
  margin: 0 5px;
}

.icon-img {
  /* width: 20px;
  height: 20px; */
}

.pagination-container {
  display: flex;
  justify-content: center;
}

/* CSS */
select:disabled,
textarea:disabled {
  color: #212529 !important;
  background-color: rgba(59, 59, 59, 0.1) !important;
  cursor: not-allowed;
}
</style>
