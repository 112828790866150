<template>
    <div class="log-cont">
        <div class="container-fluid log-container">
            <div class="row log-row">
                <div class="col-xs-12 col-md-6 col-lg-6 col-left">
                    <div class="left-cont">
                        <div class="log-form-box">
                            <form @submit.prevent="submitForm">
                                <div class="logo-for-responsive">
                                    <router-link to="javascript:void(0);" class="log-logo">
                                        <img class="img-fluid" src="images/logo-pluspromotions.png" alt="Plus Promotions">
                                    </router-link>
                                    <router-link to="/login" class="log-logo">
                                        <img class="img-fluid" src="images/logo-login.png" alt="SerpPlus">
                                    </router-link>
                                </div>
                                <h1 class="otp-title">OTP Verification</h1>
                                <p class="otp-sub-title">Please enter the OTP sent to your email.</p>
                                <!-- <a class="signin-with-google" href="javascript:void(0);">
                                    <img class="g-logo" src="images/logo-google.svg" alt="Google">
                                    <span>Sign in with Google</span>
                                </a> -->
                                <div class="form-group">
                                    <label>OTP<span class="mand-star">*</span></label>
                                    <input v-model="form.otp" class="form-control" type="text" placeholder="Enter OTP"
                                        :class="{ 'is-invalid': otpValidationFailed }" />
                                    <div v-if="otpValidationFailed" class="invalid-feedback">Please enter a valid OTP.
                                    </div>
                                </div>
                                <!-- <div class="form-devider">
                                    <span>or</span>
                                </div> -->
                                <Button class="form-common-btn btn btn-primary w-100" type="submit" :loading="isLoading">Verify OTP</Button>
                            </form>
                        </div>
                    </div>
                </div>
                <div class="col-xs-12 col-md-6 col-lg-6 col-right">
                    <div class="right-cont">
                        <a href="javascript:void(0);" class="router-link-active router-link-exact-active log-logo">
                            <img class="img-fluid" src="images/logo-pluspromotions.png" alt="Plus Promotions">
                        </a>
                        <a href="/login" class="router-link-active router-link-exact-active log-logo">
                            <img class="img-fluid" src="images/logo-login.png" alt="SerpPlus">
                        </a>
                    </div>
                </div>
            </div>
        </div>
    </div>


</template>

<script setup>
import { ref, inject } from 'vue';
import { AbilityBuilder, createMongoAbility } from '@casl/ability';
import { ABILITY_TOKEN } from '@casl/vue';
import axios from 'axios';
import { useRouter } from 'vue-router';
const ability = inject(ABILITY_TOKEN)
const router = useRouter();
import { toast } from 'vue3-toastify';
import 'vue3-toastify/dist/index.css';
import { APP_URL } from '@/Constants';

const form = ref({
    otp: ''
});

const isLoading = ref(false)

const emailValidationFailed = ref(false);
const passwordValidationFailed = ref(false);

async function submitForm() {

    try {
        isLoading.value = true
        const response = await axios.post(`${APP_URL}/verify-otp`, form.value);
        const accessToken = response.data.accessToken;
        const userAbilities = response.data.userAbilities;
        const userData = response.data.userData;
        const plan = response.data.plan;

        localStorage.setItem('accessToken', JSON.stringify(accessToken));
        localStorage.setItem('userAbilities', JSON.stringify(userAbilities));
        localStorage.setItem('userData', JSON.stringify(userData));
        localStorage.setItem('plan', JSON.stringify(plan));
        isLoading.value = false
        await getUserAbilities();
        toast.success("Login Successful!", {
            position: toast.POSITION.TOP_RIGHT,
            duration: 2000
        });
        setTimeout(() => {
            router.push('/');
        }, 2000);

    } catch (error) {
        isLoading.value = false
        // Handle specific error cases
        if (error.response) {
            if (error.response.status === 401) {
                // Unauthorized access (invalid email or password)
                const errorMessage = error.response.data && error.response.data.error
                    ? error.response.data.error
                    : 'Invalid email or password';
                toast.error(errorMessage, {
                    position: toast.POSITION.TOP_RIGHT,
                    duration: 6000 // 6000 milliseconds timeout
                });
            } else {
                // Other HTTP errors
                const errorMessage = error.response.data && error.response.data.error
                    ? error.response.data.error
                    : 'An unexpected error occurred.';
                toast.error(errorMessage, {
                    position: toast.POSITION.TOP_RIGHT,
                    duration: 6000 // 6000 milliseconds timeout
                });
            }
        } else if (error.request) {
            // The request was made, but no response was received
            toast.error("No response received from the server.", {
                position: toast.POSITION.TOP_RIGHT,
                duration: 6000 // 6000 milliseconds timeout
            });
        } else {
            // Something happened in setting up the request that triggered an error
            toast.error("An unexpected error occurred.", {
                position: toast.POSITION.TOP_RIGHT,
                duration: 6000 // 6000 milliseconds timeout
            });
        }
    }
}

const isValidEmail = (email) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
};

async function getUserAbilities() {
    try {
        const abilitiesJson = localStorage.getItem('userAbilities');

        if (abilitiesJson) {
            const abilities = JSON.parse(abilitiesJson);

            const { can, rules } = new AbilityBuilder(createMongoAbility);
            can(abilities);

            ability.update(rules);
        } else {
            console.error('User abilities not found in localStorage');
        }
    } catch (error) {
        console.error('Error retrieving user abilities:', error);
    }
}

</script>

<style scoped>
.is-invalid {
    border-color: red;
}

.invalid-feedback {
    color: red;
    font-size: 14px;
}
</style>