<template>
    <div class="admin-header-bottom">
        <nav class="admin-breadcrumb" aria-label="breadcrumb">
            <ol class="breadcrumb">
                <li class="breadcrumb-item">
                    <router-link to="/">Dashboard</router-link>
                </li>
                <li class="breadcrumb-item active" aria-current="page">Questions</li>
            </ol>
        </nav>
        <h1 class="page-title">Questions</h1>

        <div class="header-faq-search-cont">
            <h2 class="top-title"><strong>Hello,</strong> How can we help?</h2>
            <div class="top-faq-search">
                <input class="faq-top-search-input" placeholder="Search Question" v-model="searchQuery">
            </div>
            <p class="bottom-text">or select a category to quickly find the help you require</p>
        </div>
        <div class="admin-body user-faq-body">
            <div class="admin-body-box">
                <div class="common-nav-tab-cont user-faq-tab-cont">
                    <ul class="nav nav-tabs" id="myTab" role="tablist">
                        <li class="nav-item" role="presentation" v-for="(category, index) in categories"
                            :key="category.id">
                            <button class="nav-link" :class="{ 'active': activeCategory === index }"
                                @click="activeCategory = index; activeQuestion = 0"
                                :id="'question-' + category.id + '-tab'" data-bs-toggle="tab"
                                :data-bs-target="'#question-' + category.id" type="button" role="tab"
                                :aria-controls="'question-' + category.id" :aria-selected="activeCategory === index">{{
                    category.name }}</button>
                        </li>
                    </ul>
                    <div class="tab-content" id="myTabContent">
                        <div class="tab-pane fade" :class="{ 'show active': activeCategory === index }"
                            v-for="(category, index) in categories" :key="category.id" :id="'question-' + category.id"
                            role="tabpanel" :aria-labelledby="'question-' + category.id + '-tab'">
                            <div class="top-question-cont">
                                <img class="q-icon" src="images/icon-user-faq-question-white.svg" alt="Question Icon">
                                <span class="t-cont">
                                    <h2 class="title-q">{{ category.name }}</h2>
                                    <p class="sub-title-q">Get help with cancellation &amp; return</p>
                                </span>
                            </div>
                            <div class="user-faq-div">
                                <div class="common-accordian accordion" id="accordionExample">
                                    <div class="accordion-item" v-for="(question, qIndex) in category.questions"
                                        :key="question.id">
                                        <h2 class="accordion-header">
                                            <button class="accordion-button" type="button"
                                                @click="activeQuestion = qIndex"
                                                :aria-expanded="activeQuestion === qIndex"
                                                :aria-controls="'collapse-' + category.id + '-' + qIndex">{{
                    question.question }}</button>
                                        </h2>
                                        <div :id="'collapse-' + category.id + '-' + qIndex"
                                            class="accordion-collapse collapse"
                                            :class="{ 'show': activeQuestion === qIndex }"
                                            data-bs-parent="#accordionExample">
                                            <div class="accordion-body">{{ question.answer }}</div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="bottom-contacts-cont">
                            <!-- <div class="bottom-contact-row row-12 row"> -->
                            <!-- <div class="contact-col col-12 col-left col-xs-12 col-md-6 col-lg-6">
                                    <div class="contact-box phone-box">
                                        <img class="icon-contact icon-phone" src="images/icon-phone.svg"
                                            alt="Phone Icon">
                                        <h3 class="contact-title">Our Phone</h3>
                                        <p class="contact-txt">
                                            <a href="tel:+91 1234567890">+91 1234567890</a>
                                        </p>
                                    </div>
                                </div> -->
                            <!-- <div class="contact-box email-box">
                                    <img class="icon-contact icon-phone" src="images/icon-email.svg"
                                        alt="Email Icon">
                                    <h3 class="contact-title">Our Email</h3>
                                    <p class="contact-txt">
                                        <a href="mailto:serpplus1234@yopmail.com">serpplus1234@yopmail.com</a>
                                    </p>
                                </div> -->
                            <div class="contact-col col-12 col-right col-xs-12 col-md-6 col-lg-6">
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="copyright-cont">
        <p class="copyright-text">&copy; 2024 Plus Promotions UK LTD | Registered in UK, Company Number 13871077, VAT
            Number 438499640</p>
    </div>
</template>

<script setup>
import { ref, onMounted, watch } from 'vue';
import axiosIns from '@/axios';

const categories = ref([]);
const searchQuery = ref('');

const activeCategory = ref(0);
const activeQuestion = ref(0);
watch(searchQuery, async (newVal, oldVal) => {
    if (newVal !== oldVal) {
        const filteredCategories = categories.value.filter(category => {
            return category.questions.some(question => {
                return question.question.toLowerCase().includes(newVal.toLowerCase());
            });
        });
        if (searchQuery.value === '') {
            try {
                const response = await axiosIns.get('category-faq');
                categories.value = response.data;
            } catch (error) {
                console.error('Error fetching categories:', error.message);
            }
        } else {
            // Filter categories based on matched questions only
            const filteredCategoriesWithMatchedQuestions = filteredCategories.map(category => {
                return {
                    ...category,
                    questions: category.questions.filter(question =>
                        question.question.toLowerCase().includes(newVal.toLowerCase())
                    )
                };
            });
            categories.value = filteredCategoriesWithMatchedQuestions.length > 0 ? filteredCategoriesWithMatchedQuestions : categories.value;
        }
    }
});


onMounted(async () => {
    try {
        const data = await axiosIns.get('category-faq');
        categories.value = data.data;
    } catch (error) {
        console.error('Error:', error.message);
    }

});


</script>