<template>
  <loader :active="loading" message="Please wait..." />

  <!-- <aside id="nav-bar" class="admin-cont admin-sidebar l-navbar show"> -->
  <aside id="nav-bar" class="admin-sidebar l-navbar show">
    <div class="sidebar-box">
      <div class="brand-logo-cont">
        <router-link to="/" class="brand-logo">
          <img class="logo img-fluid" src="images/logo.png" alt="SerpPlus" />
          <img class="logo-icon img-fluid" src="images/logo-icon.png" alt="SerpPlus" />
        </router-link>

      </div>
      <nav class="admin-left-nav flex-shrink-0">
        <ul class="nav-ul">
          <li>
            <div class="nav-main-cat btn btn-toggle" data-bs-toggle="collapse" data-bs-target="#serp-checker"
              aria-expanded="true">
              <span class="cat-title">Serp Checker</span>
            </div>
            <div id="serp-checker" class="nav-sub-cat collapse show">
              <ul class="btn-toggle-nav">
                <li v-if="can('ADD_SERP')" :class="{ active: isRouteActive('/check-serp') }">
                  <router-link to="/check-serp">
                    🔍 <!-- Emoji for "Search" -->
                    <span class="nav-text">Check SERP</span>
                  </router-link>
                </li>
                <li v-if="can('VIEW_PROJECT')" :class="{ active: isRouteActive('/list-project') }">
                  <router-link to="/list-project">
                    📋 <!-- Emoji for "My Project" -->
                    <span class="nav-text">My Project</span>
                    <span class="badge" v-show="userType == 1">{{ projects_count }}</span>
                    <!-- Example count, replace with actual count -->
                  </router-link>
                </li>
              </ul>
            </div>

          </li>
          <li v-if="can('VIEW_USERS')">
            <div class="nav-main-cat btn btn-toggle" data-bs-toggle="collapse" data-bs-target="#authorization"
              aria-expanded="false">
              <span class="cat-title">Authorization</span>
            </div>
            <div id="authorization" class="nav-sub-cat collapse show">
              <ul class="btn-toggle-nav">
                <li v-if="can('VIEW_USERS')" :class="{ active: isRouteActive('/user-list') }">
                  <router-link to="/user-list">
                    👤 <!-- Emoji for "User" -->
                    <span class="nav-text">User</span>
                  </router-link>
                </li>
                <li v-if="can('VIEW_ROLE')" :class="{ active: isRouteActive('/role-list') }">
                  <router-link to="/role-list">
                    🔐 <!-- Emoji for "Roles" -->
                    <span class="nav-text">Roles</span>
                  </router-link>
                </li>
                <li v-if="can('VIEW_PERMISSION')" :class="{ active: isRouteActive('/permission-list') }">
                  <router-link to="/permission-list">
                    🔒 <!-- Emoji for "Permissions" -->
                    <span class="nav-text">Permissions</span>
                  </router-link>
                </li>
              </ul>

            </div>
          </li>
          <li v-if="can('VIEW_CUSTOMER')">
            <div class="nav-main-cat btn btn-toggle" data-bs-toggle="collapse" data-bs-target="#customer"
              aria-expanded="false">
              <span class="cat-title">Customers</span>
            </div>
            <div id="customer" class="nav-sub-cat collapse show">
              <ul class="btn-toggle-nav">
                <li v-if="can('VIEW_CUSTOMER')" :class="{ active: isRouteActive('/customer-list') }">
                  <router-link to="/customer-list">
                    👥 <!-- Emoji for "Customers" (people) -->
                    <span class="nav-text">Customers</span>
                  </router-link>
                </li>
              </ul>
            </div>
          </li>

          <li v-if="can('VIEW_FAQ')">
            <div class="nav-main-cat btn btn-toggle" data-bs-toggle="collapse" data-bs-target="#plan-subscription"
              aria-expanded="false">
              <span class="cat-title">Plans</span>
            </div>
            <div id="plan-subscription" class="nav-sub-cat collapse show">
              <ul class="btn-toggle-nav">
                <li :class="{ active: isRouteActive('/plan-list') }">
                  <router-link to="/plan-list">
                    📋 <!-- Emoji for "Plans" -->
                    <span class="nav-text">Plans</span>
                  </router-link>
                </li>
              </ul>
            </div>
          </li>

          <li v-if="can('VIEW_FAQ')">
            <div class="nav-main-cat btn btn-toggle" data-bs-toggle="collapse" data-bs-target="#faq"
              aria-expanded="false">
              <span class="cat-title">Faq</span>
            </div>
            <div id="faq" class="nav-sub-cat collapse show">
              <ul class="btn-toggle-nav">
                <li v-if="can('VIEW_FAQ')" :class="{ active: isRouteActive('/faq-category-list') }">
                  <router-link to="/faq-category-list">
                    📚 <!-- Emoji for "Category" -->
                    <span class="nav-text">Category</span>
                  </router-link>
                </li>
                <li v-if="can('VIEW_FAQ')" :class="{ active: isRouteActive('/faq-questions-list') }">
                  <router-link to="/faq-questions-list">
                    ❓ <!-- Emoji for "Question" -->
                    <span class="nav-text">Question</span>
                  </router-link>
                </li>
              </ul>
            </div>
          </li>

          <li v-if="can('VIEW_CUSTOMER_PLANS')">
            <div class="nav-main-cat btn btn-toggle" data-bs-toggle="collapse" data-bs-target="#plan-subscription"
              aria-expanded="false">
              <span class="cat-title">Plan Subscription</span>
            </div>
            <div id="plan-subscription" class="nav-sub-cat collapse show">
              <ul class="btn-toggle-nav">
                <li :class="{ active: isRouteActive('/purchase-list') }">
                  <router-link to="/purchase-list">
                    📅 <!-- Emoji for "Plan" -->
                    <span class="nav-text">Plan</span>
                  </router-link>
                </li>
                <li :class="{ active: isRouteActive('/credits-list') }">
                  <router-link to="/credits-list">
                    💳 <!-- Emoji for "Credits History" -->
                    <span class="nav-text">Credits History</span>
                  </router-link>
                </li>
              </ul>
            </div>
          </li>

          <li v-if="can('VIEW_CUSTOMER_PLANS')">
            <div class="nav-main-cat btn btn-toggle" data-bs-toggle="collapse" data-bs-target="#plan-subscription"
              aria-expanded="false">
              <span class="cat-title">Support</span>
            </div>
            <div id="plan-subscription" class="nav-sub-cat collapse show">
              <ul class="btn-toggle-nav">
                <li :class="{ active: isRouteActive('/faq-customer') }">
                  <router-link to="/faq-customer">
                    ❓ <!-- Emoji for "Question" -->
                    <span class="nav-text">FAQ</span>
                  </router-link>
                </li>
                <li>
                  <a href="mailto:info@pluspromotions.co.uk">
                    ✉️ <!-- Emoji for "Mail" -->
                    <span class="nav-text">Mail us</span>
                  </a>
                </li>
              </ul>
            </div>
          </li>

          <li v-if="can('VIEW_ROLE')">
            <div class="nav-main-cat btn btn-toggle" data-bs-toggle="collapse" data-bs-target="#plan-subscription"
              aria-expanded="false">
              <span class="cat-title">Settings</span>
            </div>
            <div id="plan-subscription" class="nav-sub-cat collapse show">
              <ul class="btn-toggle-nav">
                <li :class="{ active: isRouteActive('/settings-list') }">
                  <router-link to="/settings-list">
                    ⚙️ <!-- Emoji for "TopUp" -->
                    <span class="nav-text">TopUp</span>

                  </router-link>
                </li>
              </ul>
            </div>
          </li>
          <a ref="changePlanButton" class="admin-common-btn btn-change-password" data-bs-toggle="modal"
            style="cursor: pointer; display: none;" data-bs-target="#modal-plan-change">Change Plan</a>


        </ul>
      </nav>
      <div class="upgradeto-pro-box" v-if="this.userType === 1">
        <div class="box-cont">
          <h4 class="title-utp">Upgrade to PRO</h4>
          <div class="text-utp">
            <p>to get access to all features! Connect with Venus World!</p>
          </div>
          <a class="btn-gpn" data-bs-toggle="modal" data-bs-target="#modal-add-project-pricing-plan">Get Pro Now</a>
        </div>
      </div>
    </div>
  </aside>

  <!-- MODAL - ADD PROJECT - PRICING PLAN - START -->
  <div id="modal-add-project-pricing-plan" class="common-modal-cont modal fade modal-add-project-pricing-plan"
    tabindex="-1" aria-hidden="true">
    <div class="modal-dialog modal-1120 modal-dialog-centered">
      <div class="modal-content">
        <form action="">
          <div class="modal-header">
            <h5 class="modal-title mx-auto">
              <span class="mod-title">Pricing Plan</span>

            </h5>
            <!-- <button class="btn-close" type="button" data-bs-dismiss="modal" aria-label="Close"></button> -->
            <a class="btn-close" data-bs-dismiss="modal" aria-label="Close">
              <img src="images/icon-popup-close.svg" alt="Close">
            </a>
          </div>
          <div class="modal-body">
            <p class="top-msg">Checkout our best SERP + Plans now – very affordable services with accurate results.
            </p>
            <div class="modal-toggle-switch-cont switch-left-right-label">
              <div class="form-check form-switch switch-new">
                <input type="radio" class="btn-check" name="billingOptions" id="billMonthly" autocomplete="off"
                  v-model="isMonthly" :value="true">
                <label class="btn btn-secondary switch-btn btn-left" for="billMonthly">Bill Monthly</label>
                <input type="radio" class="btn-check" name="billingOptions" id="billAnnually" autocomplete="off"
                  v-model="isMonthly" :value="false">
                <label class="btn btn-secondary switch-btn btn-right" for="billAnnually">Bill Annually</label>
              </div>
            </div>
            <div class="plan-cont">
              <div class="row plan-box-row">
                <div class="plan-box-col col-xs-12 col-md-4 col-lg-4" v-for="plan in plans" :key="plan.id">
                  <div class="plan-box" :class="{ 'highlight': activeSub && activeSub.name === plan.name }">
                    <div class="plan-price">£{{ getPrice(plan) }} <sub>/ {{ billingType }}</sub></div>
                    <h2 class="plan-name">{{ plan.name }}</h2>
                    <ul class="mb-5" style="list-style: none; padding-left: 0;">
                      <li style="font-size: 16px;">💰 {{ getCredits(plan) + " Credits" }}</li>
                      <li style="font-size: 16px;">📋 {{ plan.projects_count + " Projects" }}</li>
                      <b v-if="activeSub && activeSub.name === plan.name && activeSub.is_cancelled === 1"
                        style="color: red; text-align: center; display: block; margin-top: 20px;">
                        Subscription will be cancelled but still {{ remainingDays }} days remain 🕒
                      </b>
                      <b v-if="activeSub && activeSub.name === plan.name && activeSub.is_cancelled === null"
                        style="color: green; text-align: center; display: block; margin-top: 20px;">
                        Subscription is active and there are still {{ remainingDays }} days remaining 🕒
                      </b>
                    </ul>
                    <div class="admin-box-btn-cont">
                      <a v-if="activeSub && activeSub.name === plan.name && activeSub.is_cancelled !== 1"
                        class="admin-common-btn btn-bordered btn-full btn btn-get-started mb-1 mt-4"
                        data-bs-dismiss="modal" aria-label="Close"
                        @click="handleGetStarted(plan, getPrice(plan), plan.id, true)">
                        {{ activeSub.interval == 'month' ? 'Upgrade To Yearly Plan' : 'Downgrade To Monthly Plan' }}
                      </a>

                      <a v-if="activeSub && activeSub.name === plan.name && activeSub.is_cancelled !== 1"
                        class="admin-common-btn  btn-full btn btn-cancel" data-bs-dismiss="modal" aria-label="Close"
                        @click="handleCancel()">Cancel</a>


                      <a v-else class="admin-common-btn btn-bordered btn-full btn btn-get-started"
                        data-bs-dismiss="modal" aria-label="Close"
                        @click="handleGetStarted(plan, getPrice(plan), plan.id, false)">SELECT</a>
                    </div>
                  </div>
                </div>

              </div>
            </div>
          </div>

          <StripeCheckout ref="checkoutRef" mode="subscription" :pk="publishableKey" :line-items="lineItems"
            :customer-email="email" :success-url="successURL" :cancel-url="cancelURL" @loading="v => loading = v" />
        </form>
      </div>
    </div>
  </div>
  <!-- MODAL - ADD PROJECT - PRICING PLAN - END -->
  <div id="modal-plan-change" class="common-modal-cont modal fade" tabindex="-1" aria-hidden="true">
    <div class="modal-dialog modal-690 modal-dialog-centered">
      <div class="modal-content">
        <form @submit.prevent="updateUser">
          <div class="modal-header">
            <h5 class="modal-title">
              <span class="mod-title">Change Plan</span>
            </h5>
            <a class="btn-close" data-bs-dismiss="modal" aria-label="Close">
              <img src="images/icon-popup-close.svg" alt="Close">
            </a>
          </div>
          <div class="modal-body">
            <div class="modal-form-cont change-plan-form-cont mb-4">
              <h6>Would you like to start the upgrade subscription now? If yes, press 'Now' to start immediately. If you
                prefer to upgrade after the current period ends, press 'After'. Otherwise, press 'Cancel'.</h6>
            </div>
          </div>
          <div class="modal-footer">
            <button class="admin-common-btn btn-bordered btn btn-after" type="button" data-bs-dismiss="modal"
              @click="upgradeAfter" aria-label="Close">After</button>
            <button class="admin-common-btn btn btn-primary btn-cancel" data-bs-dismiss="modal"
              type="submit">Cancel</button>

            <button class="admin-common-btn btn-bordered btn btn-now" type="button" data-bs-dismiss="modal"
              @click="upgradeNow" aria-label="Close">Now</button>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>
<script>
import { useAbility } from '@casl/vue';
import axiosIns from '@/axios';
import { StripeCheckout } from '@vue-stripe/vue-stripe';
import EventBus from '@/EventBus';
import { toast } from 'vue3-toastify';
import 'vue3-toastify/dist/index.css';
import Loader from '@/views/Loader.vue';
import { FRONT_APP_URL } from '@/Constants';
import { STRIPE_PK } from '@/Constants';

export default {
  components: {
    StripeCheckout,
    Loader,
  },
  data() {
    return {
      userType: null,
      plans: [],
      isMonthly: true,
      publishableKey: `${STRIPE_PK}`,
      lineItems: [],
      loading: false,
      activeSub: {},
      projects_count: null,
      remainingDays: null,
      prepareDataForUpgrade: {},
      successURL: `${FRONT_APP_URL}payment-done`,
      cancelURL: `${FRONT_APP_URL}payment-failed`,
    };
  },
  mounted() {
    EventBus.$on('loadCredits', data => {
      if (data) {
        this.loadUser()
      }
    })
    this.fetchPlans()
    EventBus.$on('passDetails', data => {
      if (data) {
        this.activeSub = data;
        const expireDate = this.activeSub.expire_date;
        const currentDate = new Date();
        const remainingTime = new Date(expireDate) - currentDate;
        this.remainingDays = Math.ceil(remainingTime / (1000 * 60 * 60 * 24));

        if (this.activeSub.interval == 'month') {
          this.isMonthly = true
        } else {
          this.isMonthly = false
        }
      }
    })
    // Retrieve user data from localStorage
    const userData = JSON.parse(localStorage.getItem('userData'));
    this.email = userData?.email;
    // Do something with userData, for example, set it to a reactive property
    this.userType = userData?.type;
    //this.projects_count = userData.projects_count;
  },
  methods: {
    async loadUser() {
      try {
        const data = await axiosIns.get('/user/show');
        localStorage.setItem('userData', JSON.stringify(data.data.userData));

        this.projects_count = data.data.userData.projects_count;
      } catch (error) {
        console.error('Logout failed:', error);

      }
    },
    isRouteActive(route) {
      return this.$route.path === route;
    },
    async fetchPlans() {
      try {
        const data = await axiosIns.get('plans');
        this.plans = data.data.plans;

        localStorage.setItem('userData', JSON.stringify(data.data.userData));
      } catch (error) {
        console.error('Error:', error.message);
      }
    },
    getPrice(plan) {
      return this.isMonthly ? plan.monthly_price : plan.yearly_price;
    },
    getCredits(plan) {
      return this.isMonthly ? plan.monthly_credits : plan.yearly_credits;
    },
    async handleCancel() {
      if (!confirm("Are you sure you want to cancel this subscription?")) {
        return;
      }
      this.loading = true
      try {
        const data = await axiosIns.post(`/plans/cancel-subscription/${this.activeSub.sub_id}`);
        localStorage.setItem('userData', JSON.stringify(data.data.userData));
        this.loading = false
        toast.success("Subscription cancelled successfully. Please check your email for further instructions.", {
          position: toast.POSITION.TOP_RIGHT,
          duration: 6000
        });
        location.reload();
      } catch (error) {
        this.loading = false

        console.error('Error cancelling subscription:', error);
        toast.error("An error occurred while cancelling the subscription. Please try again later.");
      }
    },
    async handleGetStarted(data, price, planId, samePlanChange) {
      const { plans_stripe: plans } = data;
      // Filter monthly and yearly plans based on the value of isMonthly
      var filteredPlans = this.isMonthly
        ? plans.filter(plan => plan.interval === 'month')
        : plans.filter(plan => plan.interval === 'year');

      if (samePlanChange) {
        if (this.activeSub.interval == 'month') {
          filteredPlans = plans.filter(plan => plan.interval === 'year');
        } else {
          filteredPlans = plans.filter(plan => plan.interval === 'month');
        }

        this.prepareDataForUpgrade = {
          subscriptionId: this.activeSub.sub_id,
          priceId: filteredPlans[0].price,
          planId: planId,
          prorationBehavior: false,
          isJob: false,
          update_on: this.activeSub.expire_date,
        }

        const button = this.$refs.changePlanButton;
        button.click();

        return false
      }

      if (filteredPlans.length > 0) {
        const selectedPlan = filteredPlans[0];

        const currentPrice = parseFloat(this.activeSub.price);
        // Check if the selected plan price is less than the current price (downgrade)
        if (parseFloat(price) < currentPrice) {
          if (confirm('Are you sure you want to downgrade your plan?')) {
            this.loading = true
            try {
              const data = await axiosIns.post(`plans/upgrade-downgrade-subscription`, {
                subscriptionId: this.activeSub.sub_id,
                priceId: selectedPlan.price,
                planId: planId,
                prorationBehavior: true,
                isJob: false,
                update_on: this.activeSub.expire_date,
              }, {
                timeout: 15000 // Timeout set to 15 seconds (15000 milliseconds)
              });
              this.loading = false
              toast.success("Plan upgraded successfully. Your subscription will be updated at the end of the current plan period.", {
                position: toast.POSITION.TOP_RIGHT,
                duration: 6000
              });
              setTimeout(() => {
                window.location.href = '/check-serp';
              }, 6000);
            } catch (error) {
              this.loading = false
              toast.success("Plan downgraded successfully. Your subscription will be updated at the end of the current plan period.", {
                position: toast.POSITION.TOP_RIGHT,
                duration: 6000
              });
              setTimeout(() => {
                window.location.href = '/check-serp';
              }, 6000);
              console.error(error);
            }
          }
        } else if (parseFloat(price) > currentPrice) {

          this.prepareDataForUpgrade = {
            subscriptionId: this.activeSub.sub_id,
            priceId: selectedPlan.price,
            planId: planId,
            prorationBehavior: false,
            isJob: false,
            update_on: this.activeSub.expire_date,
          }

          const button = this.$refs.changePlanButton;
          button.click();
        }
        else {
          const confirmation = confirm("You will now be redirected to our payment gateway to complete your transaction?");

          if (confirmation) {
            this.lineItems = [{ price: selectedPlan.price, quantity: 1 }];
            this.$refs.checkoutRef.redirectToCheckout();
          } else {
            console.log("Checkout process canceled by user.");
          }
        }
      } else {
        console.error(this.isMonthly ? 'No monthly plans found.' : 'No yearly plans found.');
      }
      return false;
    },
    async upgradeNow() {
      this.loading = true;
      try {
        await axiosIns.post(`plans/upgrade-downgrade-subscription`, this.prepareDataForUpgrade, {
          timeout: 15000
        });
        toast.success("Plan upgraded successfully. Changes take effect immediately.", {
          position: toast.POSITION.TOP_RIGHT,
          duration: 6000
        });
        setTimeout(() => {
          window.location.href = '/check-serp';
        }, 6000);
        this.loading = false;
      } catch (error) {
        this.loading = false;
      }
    },
    async upgradeAfter() {
      this.loading = true;
      this.prepareDataForUpgrade['isJob'] = false;
      try {
        await axiosIns.post(`plans/upgrade-downgrade-subscription`, this.prepareDataForUpgrade, {
          timeout: 15000
        });
        this.loading = false;

        toast.success("Plan upgrade scheduled successfully. Your subscription will be updated at the end of the current plan period.", {
          position: toast.POSITION.TOP_RIGHT,
          duration: 6000
        });
        setTimeout(() => {
          window.location.href = '/check-serp';
        }, 6000);
      } catch (error) {
        this.loading = false;
      }

    }

  },
  computed: {
    can() {
      return useAbility().can;
    },
    billingType() {
      return this.isMonthly ? 'Monthly' : 'Yearly';
    }
  }
};
</script>

<style scoped>
/* Style for unchecked switch */
.form-check-input {
  background-color: #007bff !important;
  /* Change to your desired color for unchecked state */
}

/* Style for checked switch */
.form-check-input:checked {
  background-color: #007bff !important;
  /* Change to your desired color for checked state */
}

.highlight {
  background-color: #e7f2ff;
  /* Light blue background color */
  border: 2px solid #45C9F4;
  /* Border color */
  border-radius: 5px;
  /* Optional: Adjust border radius for rounded corners */
  box-shadow: 0 0 10px rgba(0, 123, 255, 0.5);
  /* Optional: Add a shadow effect */
}


.btn-cancel {
  color: #dc3545;
  /* Red text color */
  background-color: transparent;
  /* Transparent background color */
  border-color: #dc3545;
  /* Red border color */
  transition: background-color 0.3s ease;
  /* Smooth transition */
}

.btn-cancel:hover {
  background-color: #dc3545;
  /* Red background color on hover */
  color: #fff;
  /* White text color on hover */
}

.badge {
  background-color: #007bff;
  /* Badge background color */
  color: #fff;
  /* Text color */
  border-radius: 10px;
  /* Border radius to round the badge */
  padding: 3px 6px;
  /* Padding around the text inside the badge */
  font-size: 12px;
  /* Font size of the text inside the badge */
  margin-left: 5px;
  /* Adjust this value to position the badge as desired */
}
</style>
