<template>

  <!-- <div class="live-contact">
      <div id="live-contact-box" class="live-contact-box">
          <a id="box-close" class="box-close"><img src="images/icon-popup-close.svg" alt="Close"></a>
          <form>
              <h2 class="form-title"><img src="images/icon-live-contact.svg" alt="Email" /> Contact with Us</h2>
              <div class="form-group">
                  <label>Your Email</label>
                  <input class="form-control" type="email" placeholder="mail@example.com" required>
              </div>
              <div class="form-group">
                  <label>Message</label>
                  <textarea class="form-control" placeholder="Type Your Message"></textarea>
              </div>
              <div class="form-group">
                  <button class="admin-common-btn btn btn-primary btn-submit" type="submit">Submit</button>
              </div>
          </form>
      </div>
      <a id="live-contact-btn" class="live-contact-btn" href="#"><img src="images/icon-live-contact.svg" alt="Email" /> Contact with Us</a>
  </div>  -->

  <div class="admin-header-top">
    <a id="header-toggle" class="toggle-icon-cont">
      <img class="toggle-icon icon-bar" src="images/icon-sidebar-toggle.svg" alt="Toggle Icon">
      <img class="toggle-icon icon-close" src="images/icon-sidebar-toggle-close.svg" alt="Toggle Close Icon">
    </a>
    <div class="header-user-cont">
      <router-link to='/credits-list' id="left-credits-btn" class="admin-common-btn btn-left-credits"
        v-show="userRole !== 'Superadmin'">
        <div class="credits-container">
          <div class="credit-info">
            <div class="credit-label">
              💰 Credits: <strong>{{ userCredits }}</strong>
            </div>
            <div class="credit-label">
              📋 Remaining Projects: <strong>{{ projects_count }}</strong>
            </div>
          </div>
        </div>
      </router-link>

      <a id="upgrade-plan-btn" class="admin-common-btn btn-upgrade-plan" v-show="userType === 1" data-bs-toggle="modal"
        data-bs-target="#modal-add-project-pricing-plan"> Upgrade Plan</a>

      <div class="user-box">

        <!-- <router-link id="top-notification" class="header-icon-cont icon-setting" to="/settings-list"
          v-show="userRole == 'Superadmin'">
         
          <img src="images/icon-setting.svg" alt="Setting Icon">
        </router-link> -->

        <a id="top-user" class="header-icon-cont icon-user" style="cursor: pointer;">
          <img src="images/user-avatar.webp" alt="User Icon">
        </a>

        <!-- USER PROFILE - POPUP - START -->
        <div id="profile-pop" class="common-popup-cont profile-pop">
          <div class="pop-cont">
            <a id="close-profile-pop" class="popup-close-icon">
              <img src="images/icon-popup-close.svg" alt="Close">
            </a>
            <div class="user-avatar">
              <router-link to="/user-profile">
                <img src="images/user-avatar.webp" alt="User Icon">
              </router-link>
              <router-link class="icon-edit" to="/user-profile">
                <img src="images/icon-edit.svg" alt="Edit">
              </router-link>
            </div>

            <h2 class="user-name">
              <span class="neme">{{ userName }}</span>
              <router-link class="icon-name-edit" to="/user-profile">
                <img src="images/icon-edit-name.svg" alt="Edit Name">
              </router-link>

            </h2>
            <div class="other-details">
              <p>{{ userRole }}</p>
              <p>{{ email }}</p>
            </div>
            <div class="popup-bottom">
              <a class="admin-common-btn" @click="goToAdmin" style="margin-right: 5px; cursor: pointer;"
                v-show="isAdmin">Admin
                Login</a>
              <a class="admin-common-btn" @click="logout" style="cursor: pointer;">Sign Out</a>
            </div>
          </div>
        </div>
        <!-- USER PROFILE - POPUP - END -->

      </div>

    </div>
  </div>

  <!-- MODAL - ADD PROJECT - PRICING PLAN - START -->

  <!-- MODAL - ADD PROJECT - PRICING PLAN - END -->
  <StripeCheckout ref="checkoutRef" mode="subscription" :pk="publishableKey" :line-items="lineItems"
    :success-url="successURL" :cancel-url="cancelURL" :customer-email="email" @loading="v => loading = v" />

</template>

<script>
import EventBus from '@/EventBus';
import axiosIns from '@/axios';
import $ from 'jquery';
import { toast } from 'vue3-toastify';
import 'vue3-toastify/dist/index.css';
import { StripeCheckout } from '@vue-stripe/vue-stripe';

export default {
  components: {
    StripeCheckout,
  },
  data() {
    return {
      userName: '',
      userCredits: '',
      userRole: '',
      isAdmin: false,
      projects_count: null,
    };
  },
  beforeMount() {
    const userData = localStorage.getItem('userData');
    if (userData) {
      const parsedUserData = JSON.parse(userData);
      this.userName = parsedUserData.name ?? '';
      this.userCredits = parsedUserData.credits ?? '';
      this.userRole = parsedUserData.role[0];
      this.email = parsedUserData.email;
      this.userType = parsedUserData.type;
    }
  },
  mounted() {

    const userData = localStorage.getItem('userData');
    if (userData) {
      const parsedUserData = JSON.parse(userData);
      this.userName = parsedUserData.name ?? '';
      this.userCredits = parsedUserData.credits ?? '';
      this.userRole = parsedUserData.role[0];
      this.email = parsedUserData.email;
      this.userType = parsedUserData.type;
    }
    EventBus.$on('loadCredits', data => {
      if (data) {
        this.loadUser()
      }
    })
    $(document).ready(function () {
      $('#top-user').on('click', function (event) {
        event.stopPropagation();
        var profilePop = $('#profile-pop');
        if (profilePop.css('display') === 'none') {
          profilePop.css('display', 'block');
          $('body').on('click', closePopupOutsideTwo);
        } else {
          profilePop.css('display', 'none');
          $('body').off('click', closePopupOutsideTwo);
        }
      });

      function closePopupOutsideTwo(event) {
        const popup = $('#profile-pop');
        if (!popup.is(event.target) && event.target !== $('#top-user')[0]) {
          popup.css('display', 'none');
          $('body').off('click', closePopupOutsideTwo);
        }
      }

      $('#close-profile-pop').on('click', function (event) {
        event.stopPropagation();
        $('#profile-pop').css('display', 'none');
      });


    });

    $(document).ready(function () {
      $("#live-contact-btn").click(function () {
        $("#live-contact-box").slideToggle("slow");
        $("#live-contact-btn").slideToggle("hide");
      });
      $("#box-close").click(function () {
        $("#live-contact-box").slideToggle("hide");
        $("#live-contact-btn").slideToggle("show");
      });
    });


    const notificationButton = $('#top-notification');
    const notificationPopup = $('#notification-pop');
    const closeNotificationButton = $('#close-notification-pop');

    const checkSerpPopup = $('#checkSerp-pop');

    checkSerpPopup.on('click', function (event) {
      event.stopPropagation();
      checkSerpPopup.css('display', 'block');
      $('body').on('click', this.closePopupOutside);
    });

    notificationButton.on('click', function (event) {
      event.stopPropagation();
      notificationPopup.css('display', 'block');
      $('body').on('click', this.closePopupOutside);
    });

    closeNotificationButton.on('click', function (event) {
      event.stopPropagation();
      notificationPopup.css('display', 'none');
    });

    const showNavbar = (toggleId, navId, bodyId, headerId) => {
      const toggle = $(`#${toggleId}`),
        nav = $(`#${navId}`),
        bodypd = $(`#${bodyId}`),
        headerpd = $(`#${headerId}`);

      if (toggle && nav && bodypd && headerpd) {
        toggle.on('click', () => {
          nav.toggleClass('show');
          toggle.toggleClass('bx-x');
          bodypd.toggleClass('body-pd');
          headerpd.toggleClass('body-pd');
        });
      }
    };

    showNavbar('header-toggle', 'nav-bar', 'body-pd', 'header');

    const linkColor = $('.nav_link');

    function colorLink() {
      if (linkColor) {
        linkColor.removeClass('active');
        $(this).addClass('active');
      }
    }
    linkColor.on('click', colorLink);
  },
  methods: {
    closePopupOutside(event) {
      const popup = $('#notification-pop');
      if (!popup.is(event.target) && event.target !== $('#top-notification')[0]) {
        popup.css('display', 'none');
        $('body').off('click', this.closePopupOutside);
      }
    },
    async logout() {
      try {
        await axiosIns.get('/logout');
        localStorage.clear();
        toast.success("Logout Successful!", {
          position: toast.POSITION.TOP_RIGHT,
          duration: 6000 // 6000 milliseconds timeout
        });
        setTimeout(() => {
          window.location.reload()
        }, 1000);
      } catch (error) {
        console.error('Logout failed:', error);
        toast.error("Logout Failed!", {
          position: toast.POSITION.TOP_RIGHT,
          duration: 6000 // 6000 milliseconds timeout
        });
      }
    },
    async loadUser() {
      try {
        const data = await axiosIns.get('/user/show');
        localStorage.setItem('userData', JSON.stringify(data.data.userData));
        this.projects_count = data.data.userData.projects_count;

        const userData = localStorage.getItem('userData');
        const isAdminn = localStorage.getItem('isAdmin')
        this.isAdmin = isAdminn == 'true' ? true : false
        if (userData) {
          const parsedUserData = JSON.parse(userData);
          this.userName = parsedUserData.name ?? '';
          this.userCredits = parsedUserData.credits ?? '';
          this.userRole = parsedUserData.role[0];
          this.email = parsedUserData.email;

        }
      } catch (error) {
        console.error('Logout failed:', error);

      }
    },
    async goToAdmin() {
      const response = await axiosIns.get(`auth/change-auth/${1}`);

      const accessToken = response.data.accessToken;
      const userAbilities = response.data.userAbilities;
      const userData = response.data.userData;

      localStorage.setItem('accessToken', JSON.stringify(accessToken));
      localStorage.setItem('userAbilities', JSON.stringify(userAbilities));
      localStorage.setItem('userData', JSON.stringify(userData));
      localStorage.setItem('isAdmin', false);

      toast.success("Login Successfully!", {
        position: toast.POSITION.TOP_RIGHT,
        duration: 6000 // 6000 milliseconds timeout
      });

      window.location.href = '/';
    }
  },
};
</script>

<style scoped>
/* Style for unchecked switch */
.form-check-input {
  background-color: #007bff !important;
  /* Change to your desired color for unchecked state */
}

/* Style for checked switch */
.form-check-input:checked {
  background-color: #007bff !important;
  /* Change to your desired color for checked state */
}

.credits-container {
  display: flex;
  align-items: center;
}

.credit-icon {
  margin-right: 10px;
}

.credit-info {
  display: flex;
  flex-direction: column;
}

.credit-label {
  font-weight: bold;
}

.projects-info {
  font-size: 14px;
  color: #888;
}
</style>