<template>
    <loader :active="loading" message="Please wait..." />

    <div class="admin-header-bottom">
        <nav class="admin-breadcrumb" aria-label="breadcrumb">
            <ol class="breadcrumb">
                <li class="breadcrumb-item">
                    <router-link to="/">Dashboard</router-link>
                </li>
                <li class="breadcrumb-item active" aria-current="page">Purchase History</li>
            </ol>
        </nav>
        <h1 class="page-title">Purchase History</h1>
        <div class="header-btn-cont mb-0">
            <a class="admin-common-btn mb-4" data-bs-toggle="modal" data-bs-target="#modal-add-credits"
                v-show="userData.type == 2">Add Top Up</a>
            <a class="admin-common-btn mb-4" data-bs-toggle="modal" data-bs-target="#modal-add-project-pricing-plan"
                v-show="userData.type == 2">New Plan</a>
        </div>
    </div>

    <div class="admin-body">
        <div class="admin-box-row row">
            <div class="admin-box-col col-xs-12 col-md-12 col-lg-12">
                <div class="table-top">
                    <input class="table-search-input" placeholder="Search Plan">
                </div>
                <div class="data-table-cont no-pagination">
                    <div class="table-responsive table-purchase-history">
                        <!-- <table id="example" class="data-table table table-border-none table-border-box ptb-10 mb-0" style="position: relative; top: -22px;"> -->
                        <table id="example" class="data-table table table-striped table-border-none mb-0">
                            <thead class="sticky-top">
                                <tr>
                                    <th class="no-wrap min-width130">Plan Name</th>
                                    <th class="no-wrap">Purchase Date</th>
                                    <th class="no-wrap">Validity</th>
                                    <th class="no-wrap">Frequency</th>
                                    <th class="no-wrap">Price</th>
                                    <th class="no-wrap">Cancelled</th>
                                    <th class="no-wrap">Credits</th>
                                    <th class="no-wrap">Subscription</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr v-for="(purchase, index) in purchaseHistory" :key="index">
                                    <td class="no-wrap" :class="{ 'highlighted-row': purchase.highlighted }">{{ purchase.name || 'N/A' }}</td>
                                    <td class="no-wrap responsive-wrap max-width110-mobile" :class="{ 'highlighted-row': purchase.highlighted }">{{ purchase.purchase_date || 'N/A' }}</td>
                                    <td class="no-wrap responsive-wrap max-width110-mobile" :class="{ 'highlighted-row': purchase.highlighted }">{{ purchase.expire_date || 'N/A' }}</td>
                                    <td class="no-wrap" :class="{ 'highlighted-row': purchase.highlighted }">{{ purchase.interval || 'N/A' }}</td>
                                    <td class="no-wrap" :class="{ 'highlighted-row': purchase.highlighted }">{{ purchase.price ? '$' + purchase.price : 'N/A' }}</td>
                                    <td class="no-wrap" :class="{ 'highlighted-row': purchase.highlighted }" :style="{ 'font-size': purchase.is_cancelled == 1 ? '16px' : 'inherit', 'color': purchase.is_cancelled == 1 ? 'red' : 'inherit' }">
                                        {{ purchase.is_cancelled == 1 ? 'Yes' : 'No' }}
                                    </td>
                                    <td class="no-wrap text-success" :class="{ 'highlighted-row': purchase.highlighted }">{{ purchase.credits || 'N/A' }}</td>
                                    <td class="no-wrap" :class="{ 'highlighted-row': purchase.highlighted }">
                                        <!-- Text with underline for managing plan -->
                                        <span class="manage-plan" @click="passDetails(purchase)"
                                            v-if="purchase.highlighted && purchase.is_cancelled == null"
                                            data-toggle="tooltip" data-bs-toggle="modal"
                                            data-bs-target="#modal-add-project-pricing-plan" data-placement="top"
                                            title="Manage Plan">
                                            <u>Manage Plan</u>
                                        </span>
                                        <!-- Text with underline for managing plan -->
                                        <span class="manage-plan" v-else data-toggle="tooltip" data-placement="top" title="Manage Plan">
                                            -
                                        </span>
                                    </td>




                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="copyright-cont">
        <p class="copyright-text">&copy; 2024 Plus Promotions UK LTD | Registered in UK, Company Number 13871077, VAT
            Number 438499640</p>
    </div>

    <!-- MODAL - ADD Credits - START -->
    <div id="modal-add-credits" class="common-modal-cont modal fade" tabindex="-1" aria-hidden="true">
        <div class="modal-dialog modal-490 modal-dialog-centered">
            <div class="modal-content">
                <form action="">
                    <div class="modal-header">
                        <h5 class="modal-title">
                            <span class="mod-title">Add Credits</span>

                        </h5>
                        <!-- <button class="btn-close" type="button" data-bs-dismiss="modal" aria-label="Close"></button> -->
                        <a class="btn-close" data-bs-dismiss="modal" aria-label="Close">
                            <img src="images/icon-popup-close.svg" alt="Close">
                        </a>
                    </div>
                    <div class="modal-body">
                        <div class="modal-form-cont check-serp-form-cont">
                            <div class="form-group">
                                <label for="creditAmount">Amount</label>
                                <select class="form-control" id="creditAmount" v-model="selectedAmount"
                                    @change="updateSelectedCredits">
                                    <option v-for="(option, index) in creditOptions" :key="index" :value="option.value">
                                        {{ option.label }}
                                    </option>
                                </select>
                                <div class="selected-credits" v-if="selectedCredits">{{ selectedCredits }}</div>

                            </div>
                        </div>
                    </div>
                    <div class="modal-footer">
                        <button class="admin-common-btn btn btn-primary btn-next" @click="buyNow()" type="button"
                            data-bs-dismiss="modal">
                            Submit
                        </button>
                    </div>
                </form>
            </div>
        </div>
    </div>
    <!-- MODAL - ADD Credits - END -->


</template>

<script>
import 'vue3-toastify/dist/index.css';
import axiosIns from '@/axios';
import Loader from '../Loader.vue';
import { toast } from 'vue3-toastify';
import 'vue3-toastify/dist/index.css';
import EventBus from '@/EventBus';

export default {
    components: {
        Loader
    },
    data() {
        return {
            purchaseHistory: [],
            creditOptions: [],
            selectedCredits: '',
            selectedAmount: '',
            userData: [],
            plan: [],
            userType: '',
            loading: false,
        };
    },
    beforeMount() {
        this.loadPurchaseHistory();
        this.loadAmmount();
        const data = localStorage.getItem('userData');
        this.userData = JSON.parse(data);
    },
    methods: {
        async loadAmmount() {
            try {
                const response = await axiosIns.get('/plans/get-amount');
                this.creditOptions = response.data.data;

                this.creditOptions.sort((a, b) => a.value - b.value);

            } catch (error) {
                console.error('Error generating payment intent:', error);
            }
        },
        async fetchCustomerPlan() {
            this.loading = true;
            try {
                const response = await axiosIns.get('/plans/list-customer-plans');
                const customerPlan = response.data.regular_subscriptions.concat(response.data.top_up_subscriptions);
                this.loading = false;

                // Sort plans by purchase date in descending order
                customerPlan.sort((a, b) => {
                    const dateA = new Date(a.purchase_date);
                    const dateB = new Date(b.purchase_date);
                    return dateB - dateA;
                });

                return customerPlan;
            } catch (error) {
                console.error('Error fetching plans:', error);
                return [];
            }
        },

        async generatePaymentIntent() {
            this.loading = true;
            try {
                const response = await axiosIns.post('/get-checkout-url', { credits: this.selectedAmount * 100, title: this.creditsShow });
                const checkoutUrl = response.data.url;
                this.loading = false;
                window.open(checkoutUrl, '_blank');
            } catch (error) {
                console.error('Error generating payment intent:', error);
            }
        },

        buyNow() {
            if (!this.selectedAmount) {
                alert('Please select an amount.');
                return;
            }
            this.generatePaymentIntent();
        },
        isExpired(expireDate) {
            if (!expireDate) return true; // Treat as expired if expireDate is not provided
            const now = new Date();
            const expire = new Date(expireDate);
            return now > expire;
        },
        async loadPurchaseHistory() {
            this.loading = true

            if (JSON.parse(localStorage.getItem('userData'))?.type === 2) {
                this.purchaseHistory = await this.fetchCustomerPlan();

                const matchedEntries = this.purchaseHistory.filter(purchase => purchase.name === JSON.parse(localStorage.getItem('plan'))?.name);

                let latestValidEntry;
                let latestValidIndex = -1;

                matchedEntries.forEach((entry, index) => {
                    if (!this.isExpired(entry.expire_date) && (!latestValidEntry || entry.created_at > latestValidEntry.created_at)) {
                        latestValidEntry = entry;
                        latestValidIndex = index;
                    }
                });

                // Set highlighted to true for the latest valid entry
                if (latestValidEntry) {
                    matchedEntries[latestValidIndex].highlighted = true;
                }

                console.log(latestValidEntry);
            } else {
                this.purchaseHistory = await this.fetchCustomerPlan();
            }
            //EventBus.$emit('refreshPage', true);

        },
        async passDetails(purchase) {
            EventBus.$emit('passDetails', purchase);
        },
        async cancelPlan(sub_id) {
            if (!confirm("Are you sure you want to cancel this subscription?")) {
                return;
            }
            this.loading = true;
            try {
                const data = await axiosIns.post(`/plans/cancel-subscription/${sub_id}`);
                localStorage.setItem('userData', JSON.stringify(data.data.userData));
                this.loading = false;
                toast.success("Subscription cancelled successfully. Please check your email for further instructions.", {
                    position: toast.POSITION.TOP_RIGHT,
                    duration: 6000
                });
                location.reload();
            } catch (error) {
                console.error('Error cancelling subscription:', error);
                clearInterval(intervalId); // Clear interval in case of error
                toast.error("An error occurred while cancelling the subscription. Please try again later.");
            }
        },



        async updateSelectedCredits(event) {
            const selectedValue = event.target.value;
            const selectedOption = this.creditOptions.find(option => option.value === parseInt(selectedValue));
            this.selectedCredits = selectedOption ? `Included ${selectedOption.credits} credits` : '';
        },
    },

};


</script>

<style scoped>
.selected-credits {
    padding: 10px;
    border-radius: 5px;
    background-color: #f0f0f0;
    margin-top: 10px;
    display: inline-block;
    font-size: 14px;
}

.form-check-input {
    background-color: #007bff !important;
    /* Change to your desired color for unchecked state */
}

/* Style for checked switch */
.form-check-input:checked {
    background-color: #007bff !important;
    /* Change to your desired color for checked state */
}

.highlighted-row {
    background-color: #8ad8ff !important;
    /* Set the background color for the highlighted row */
}

.btn[disabled] {
    display: none;
    /* Hide disabled buttons */
}

.manage-plan {
    cursor: pointer;
    /* Show pointer cursor on hover */
    text-decoration: none;
    /* Remove default underline */
}

.manage-plan:hover {
    text-decoration: underline;
    /* Underline text on hover */
}
</style>