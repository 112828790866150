<template>
  <!-- <div class="admin-layout"> -->
  <div class="container-fluid admin-container">
    <Sidebar />
    <!-- <div class="main-content"> -->
    <section class="admin-body-cont">
      <div id="header" class="admin-header header body-pd">
        <Header />
      </div> 
      <router-view></router-view>         
      <!-- <Footer /> -->
    </section>
  </div>
</template>
  
<script>
import Sidebar from "@/components/Sidebar.vue";
import Header from "@/components/Header.vue";
// import Footer from "@/components/Footer.vue";

export default {
  components: {
    Sidebar,
    Header,
    // Footer,
  },
};
</script> 
  
<style scoped>
.admin-layout {
  display: flex;
  height: 100vh; /* Ensure the layout fills the entire viewport height */
}

.main-content {
  flex: 1;
  overflow: auto; /* Enable scrolling if content exceeds container size */
  padding: 20px;
  box-sizing: border-box; /* Include padding in the total width/height calculation */
}
</style>
