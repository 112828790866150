<template>
    <div class="log-cont">
        <div class="container-fluid log-container">
            <div class="row log-row">
                <div class="col-xs-12 col-md-6 col-lg-6 col-left">
                    <div class="left-cont">
                        <div class="log-form-box">
                            <form @submit.prevent="submitForm">
                                <div class="logo-for-responsive">
                                    <router-link to="javascript:void(0);" class="log-logo">
                                        <img class="img-fluid" src="images/logo-pluspromotions.png" alt="Plus Promotions">
                                    </router-link>
                                    <router-link to="/login" class="log-logo">
                                        <img class="img-fluid" src="images/logo-login.png" alt="SerpPlus">
                                    </router-link>
                                </div>
                                <h1 class="log-title">Sign Up</h1>
                                <p class="log-sub-title">Enter your email and password to sign up!</p>
                                <div class="form-group">
                                    <label>Name<span class="mand-star">*</span></label>
                                    <input v-model="form.name" class="form-control" type="text" placeholder="Mark john"
                                        :class="{ 'is-invalid': nameValidationFailed }" />
                                    <div v-if="nameValidationFailed" class="invalid-feedback">Please enter a valid name.
                                    </div>
                                </div>
                                <div class="form-group">
                                    <label>Email<span class="mand-star">*</span></label>
                                    <input v-model="form.email" class="form-control" type="email"
                                        placeholder="mail@example.com"
                                        :class="{ 'is-invalid': emailValidationFailed }" />
                                    <div v-if="emailValidationFailed" class="invalid-feedback">Please enter a valid
                                        email
                                        address.</div>
                                </div>
                                <div class="form-group">
                                    <label>Password<span class="mand-star">*</span></label>
                                    <input v-model="form.password" @input="validatePassword" class="form-control"
                                        type="password" placeholder="Min. 8 characters"
                                        :class="{ 'is-invalid': passwordValidationFailed }" />
                                    <div v-if="passwordValidationFailed" class="invalid-feedback">
                                        Password must be at least 8 characters and contain at least one symbol and one
                                        number.
                                    </div>
                                </div>
                                <!-- <div class="form-devider">
                                    <span>or</span>
                                </div> -->
                                <!-- <div class="rm-fp-cont d-flex justify-content-between">
                                    <div class="form-text forgot-pass-cra">
                                        Already have an account ? <router-link to="/login"
                                            class="form-a">Login</router-link>
                                    </div>
                                </div> -->
                                <div class="form-text forgot-pass-cra mb-24">
                                    Already have an account ? <router-link to="/login"
                                        class="form-a">Login</router-link>
                                </div>
                                <Button class="form-common-btn btn btn-primary w-100" type="submit"
                                    :loading="isLoading">Sign Up</Button>
                            </form>
                        </div>
                    </div>
                </div>
                <div class="col-xs-12 col-md-6 col-lg-6 col-right">
                    <div class="right-cont">
                        <a href="javascript:void(0);" class="router-link-active router-link-exact-active log-logo">
                            <img class="img-fluid" src="images/logo-pluspromotions.png" alt="Plus Promotions">
                        </a>
                        <a href="/login" class="router-link-active router-link-exact-active log-logo">
                            <img class="img-fluid" src="images/logo-login.png" alt="SerpPlus">
                        </a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script setup>
import axiosIns from '@/axios';
import { ref } from 'vue';
import { useRouter } from 'vue-router';
import { toast } from 'vue3-toastify';

const router = useRouter();

const form = ref({
    name: '',
    email: '',
    password: ''
})

const isLoading = ref(false)

const nameValidationFailed = ref(false);
const emailValidationFailed = ref(false);
let passwordValidationFailed = false;

const isDisposableEmail = async (email) => {
    try {
        const response = await axiosIns.get(`https://disposable.debounce.io/?email=${email}`);
        return response.data.disposable;
    } catch (error) {
        console.error('Error checking disposable email:', error);
        return false;
    }
};

const validatePassword = () => {
    const password = form.value.password;
    // Regular expression to validate password
    const regex = /^(?=.*[a-zA-Z])(?=.*\d)(?=.*[\W_]).{8,}$/;
    passwordValidationFailed = !regex.test(password);
};

const submitForm = async () => {
    if (!passwordValidationFailed) {
        nameValidationFailed.value = false;
        emailValidationFailed.value = false;

        // Validate name
        if (!form.value.name.trim()) {
            nameValidationFailed.value = true;
        }

        // Validate email
        if (!isValidEmail(form.value.email)) {
            emailValidationFailed.value = true;
        }

        // Validate password
        if (form.value.password.length < 8) {
            passwordValidationFailed.value = true;
        }

        // Check if any validation failed
        if (nameValidationFailed.value || emailValidationFailed.value || passwordValidationFailed.value) {
            toast.error("Please fill in all fields correctly.", {
                position: toast.POSITION.TOP_RIGHT,
                duration: 6000 // 6000 milliseconds timeout
            });
            return;
        }
        const isDisposable = await isDisposableEmail(form.value.email);
        if (isDisposable !== 'false') {
            toast.error('Please provide a valid and non-disposable email address.', {
                position: toast.POSITION.TOP_RIGHT,
                duration: 6000 // 6000 milliseconds timeout
            });
            return;
        }

        try {
            isLoading.value = true
            const response = await axiosIns.post('/register', form.value);

            if (response.data.message === 'Successfully created user!') {
                toast.success("Registration Successful! Please check your email for verification instructions.", {
                    position: toast.POSITION.TOP_RIGHT,
                    duration: 6000 // 6000 milliseconds timeout
                });
                setTimeout(() => {
                    router.push('/login');
                }, 6000);
                isLoading.value = false
            } else {
                toast.error("Registration Failed. Please try again later.", {
                    position: toast.POSITION.TOP_RIGHT,
                    duration: 6000 // 6000 milliseconds timeout
                });
            }
        } catch (error) {
            isLoading.value = false
            if (error.response.status == 422) {
                toast.error('The email has already been taken!', {
                    position: toast.POSITION.TOP_RIGHT,
                    duration: 6000 // 6000 milliseconds timeout
                });
            }

        }
    }

};


const isValidEmail = (email) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
};


</script>

<style scoped>
.is-invalid {
    border-color: red;
}

.invalid-feedback {
    color: red;
    font-size: 14px;
}
</style>