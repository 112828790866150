<template>
    <div class="admin-header-bottom dashboard-cont" v-show="userRole == 'Superadmin'">

        <h1 class="page-title">Main Dashboard</h1>
        <div class="top-info-box-cont">
            <div class="top-info-row row-20 row">
                <div class="info-box-col col-20 col-xs-12 col-md-6 col-lg-3">
                    <div class="info-box">
                        <img class="icon-info-box" src="images/icon-profile-user.svg" alt="Total User">
                        <div class="txt-cont">
                            <p class="info-title">Total User</p>
                            <h2 class="info-data">{{ dashboardData ? dashboardData.totalUsers : 0 }}</h2>
                        </div>
                    </div>
                </div>
                <div class="info-box-col col-20 col-xs-12 col-md-6 col-lg-3">
                    <div class="info-box">
                        <img class="icon-info-box" src="images/icon-card-tick.svg" alt="Total Paid User">
                        <div class="txt-cont">
                            <p class="info-title">Total Paid User</p>
                            <h2 class="info-data">{{ dashboardData ? dashboardData.totalPaidUsers : 0 }}</h2>
                        </div>
                    </div>
                </div>
                <div class="info-box-col col-20 col-xs-12 col-md-6 col-lg-3">
                    <div class="info-box">
                        <img class="icon-info-box" src="images/icon-receipt-search.svg"
                            alt="Total serp per day">
                        <div class="txt-cont">
                            <p class="info-title">Total keywords per day</p>
                            <h2 class="info-data">{{ dashboardData ? dashboardData.dailyKeywords : 0
                                }}/<sub>day</sub></h2>
                        </div>
                    </div>
                </div>
                <div class="info-box-col col-20 col-xs-12 col-md-6 col-lg-3">
                    <div class="info-box">
                        <img class="icon-info-box" src="images/icon-question.svg" alt="Total request of serp">
                        <div class="txt-cont">
                            <p class="info-title">Total request of serp</p>
                            <h2 class="info-data">{{ dashboardData ? dashboardData.dailySerps : 0
                                }}/<sub>day</sub></h2>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <!-- <div class="dashboard-body">
    </div>  -->

    <!-- <div class="copyright-cont">
        <p class="copyright-text">&copy; 2024 Plus Promotions UK LTD | Registered in UK, Company Number 13871077, VAT Number 438499640</p>
    </div> -->

    <div class="copyright-cont">
        <p class="copyright-text">© 2024 Plus Promotions UK LTD | Registered in UK, Company Number 13871077, VAT Number 438499640</p>
    </div>
    
</template>

<script>
import axiosIns from '@/axios';

export default {
    data() {
        return {
            dashboardData: null,
            userRole: null,
        };
    },
    mounted() {
        const userData = JSON.parse(localStorage.getItem('userData'));
        this.userRole = userData?.role[0];
    },
    methods: {
        async fetchDashboardData() {
            try {
                const response = await axiosIns.get('/dashboard');
                this.dashboardData = response.data;
            } catch (error) {
                this.error = error.message;
            } finally {
                this.loading = false;
            }
        }
    },
    created() {
        this.fetchDashboardData();
    }
};
</script>