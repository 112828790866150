<template>

    <loader :active="loading" message="Please wait..." />

    <div class="admin-header-bottom">
        <nav class="admin-breadcrumb" aria-label="breadcrumb">
            <ol class="breadcrumb">
                <li class="breadcrumb-item">
                    <router-link to="/"
                        style="color: var(--color-admin-breadcrumb);font-weight: 500;text-decoration: none;">Dashboard</router-link>
                </li>
                <li class="breadcrumb-item active" aria-current="page">Manage Question</li>
            </ol>
        </nav>
        <h1 class="page-title">Manage Question</h1>
        <!-- <div class="header-btn-cont">
            <a class="admin-common-btn " data-bs-toggle="modal" @click="addQuestion"
                data-bs-target="#modal-add-question" style="cursor: pointer;">Add
                Question</a>
        </div> -->
    </div>

    <div class="admin-body">
        <div class="admin-body-box">
            <div class="admin-body-bottom">
                <div class="table-top">
                    <div class="header-top-btn-cont">
                        <a class="admin-common-btn " data-bs-toggle="modal" @click="addQuestion" data-bs-target="#modal-add-question" style="cursor: pointer;">Add Question</a>
                    </div>
                </div> 
                <div class="data-table-cont">
                    <div class="table-responsive table-manage-question">
                        <table id="example" class="data-table table table-striped table-border-none spacing5">
                            <thead class="sticky-top">
                                <tr>
                                    <th class="no-wrap width25 text-start">Category</th>
                                    <th class="no-wrap width25 min-width230">Question</th>
                                    <th class="no-wrap width25 min-width230" style="min-width: 200px;">Answer</th>
                                    <th class="no-wrap width25 text-end">Actions</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr v-for="(item, index) in questions" :key="index">
                                    <td class="no-wrap valign-top text-start">{{ item.categoryName }}</td>
                                    <td class="valign-top">{{ item.question }}</td>
                                    <td class="valign-top">{{ item.answer }}</td>
                                    <td class="no-wrap valign-top text-end">
                                        <div class="action-icon-cont justify-content-end">
                                            <a class="action-icon icon-edit" @click="editQuestion(item)"
                                                data-bs-toggle="modal" data-bs-target="#modal-edit-question">
                                                <img class="icon-img" src="images/icon-edit-user.svg" alt="Edit">
                                            </a>
                                            <a class="action-icon icon-delete" @click="deleteQuestion(item)">
                                                <img class="icon-img" src="images/icon-delete.svg" alt="Delete">
                                            </a>
                                        </div>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <!-- MODAL - ADD QUESTION - START -->
    <div id="modal-add-question" class="common-modal-cont modal fade" tabindex="-1" aria-hidden="true">
        <div class="modal-dialog modal-490 modal-dialog-centered">
            <div class="modal-content">
                    <div class="modal-header">
                        <h5 class="modal-title">
                            <span class="mod-title">Add Question</span>
                            <!-- <div class="info-cont tooltip-trigger">
                                <img class="info-icon" src="images/icon-info-circle.svg" alt="Info">
                                <div class="custom-tooltip">
                                    <div class="tooltip-cont">
                                        <img class="icon" src="images/icon-info-circle-white.svg" alt="Info">
                                        <span class="text">Add Question</span>
                                    </div>
                                </div>
                            </div> -->
                        </h5>
                        <a class="btn-close"  data-bs-dismiss="modal" aria-label="Close">
                            <img src="images/icon-popup-close.svg" alt="Close">
                        </a>
                    </div>
                    <div class="modal-body">
                        <div class="modal-form-cont check-serp-form-cont">
                            <div class="form-group">
                                <label>Category</label>
                                <select class="form-control" required v-model="form.category_id">
                                    <option value="">Select Category</option>
                                    <option v-for="(category, index) in categories" :key="index" :value="category.id">{{
                    category.name }}</option>
                                </select>

                            </div>
                            <div class="form-group">
                                <label>Question</label>
                                <input class="form-control" type="text" v-model="form.question"
                                    placeholder="Enter Question" required>
                            </div>
                            <div class="form-group">
                                <label>Answer</label>
                                <textarea class="form-control" placeholder="Enter Answer" v-model="form.answer"
                                    required></textarea>
                            </div>
                        </div>
                    </div>
                    <div class="modal-footer">
                        <button class="admin-common-btn btn-bordered btn btn-cancel" type="button"
                            data-bs-dismiss="modal" aria-label="Close">Cancel</button>
                        <button class="admin-common-btn btn btn-primary btn-save" @click="saveQuestion"
                            data-bs-dismiss="modal">Save</button>
                    </div>
            </div>
        </div>
    </div>
    <!-- MODAL - ADD QUESTION - END -->

    <!-- MODAL - EDIT QUESTION - START -->
    <div id="modal-edit-question" class="common-modal-cont modal fade" tabindex="-1" aria-hidden="true">
        <div class="modal-dialog modal-490 modal-dialog-centered">
            <div class="modal-content">
                    <div class="modal-header">
                        <h5 class="modal-title">
                            <span class="mod-title">Edit Question</span>
                            <!-- <div class="info-cont tooltip-trigger">
                                <img class="info-icon" src="images/icon-info-circle.svg" alt="Info">
                                <div class="custom-tooltip">
                                    <div class="tooltip-cont">
                                        <img class="icon" src="images/icon-info-circle-white.svg" alt="Info">
                                        <span class="text">Edit Question</span>
                                    </div>
                                </div>
                            </div> -->
                        </h5>
                        <a class="btn-close"  data-bs-dismiss="modal" aria-label="Close">
                            <img src="images/icon-popup-close.svg" alt="Close">
                        </a>
                    </div>
                    <div class="modal-body">
                        <div class="modal-form-cont check-serp-form-cont">
                            <div class="form-group">
                                <label>Category</label>
                                <select class="form-control" required v-model="form.category_id">
                                    <option value="">Select Category</option>
                                    <option v-for="(category, index) in categories" :key="index" :value="category.id">{{
                    category.name }}</option>
                                </select>
                            </div>
                            <div class="form-group">
                                <label>Question</label>
                                <input class="form-control" type="text" v-model="form.question"
                                    placeholder="Enter Question" required>
                            </div>
                            <div class="form-group">
                                <label>Answer</label>
                                <textarea class="form-control" placeholder="Enter Answer" v-model="form.answer"
                                    required></textarea>
                            </div>
                        </div>
                    </div>
                    <div class="modal-footer">
                        <button class="admin-common-btn btn-bordered btn btn-cancel" type="button"
                            data-bs-dismiss="modal" aria-label="Close">Cancel</button>
                        <button class="admin-common-btn btn btn-primary btn-update" @click="updateQuestion"
                            data-bs-dismiss="modal">Update</button>
                    </div>
            </div>
        </div>
    </div>
    <!-- MODAL - EDIT QUESTION - END -->
    <div class="copyright-cont">
        <p class="copyright-text">&copy; 2024 Plus Promotions UK LTD | Registered in UK, Company Number 13871077, VAT
            Number 438499640</p>
    </div>
</template>

<script setup>
import { ref, computed, onMounted } from 'vue';
import axiosIns from '../../axios';
import { useAbility } from '@casl/vue';
import { toast } from 'vue3-toastify';
import 'vue3-toastify/dist/index.css';
const { can } = useAbility();
import loader from '../Loader.vue';

const questions = ref([]);
const categories = ref([]);
const questionId = ref('');

const form = ref({});
const loading = ref(false)

const addQuestion = () => {
    form.value = {};
};

const editQuestion = (question) => {
    form.value = question;
    questionId.value = question.id;

};

const saveQuestion = async () => {
    try {
        await axiosIns.post('questions', form.value);
        toast.success('Question Created', {
            position: toast.POSITION.TOP_RIGHT,
            duration: 6000
        });
    } catch (error) {
        console.error('Error:', error.message);
    }
    fetchQuestions();
};

const updateQuestion = async () => {
    try {
        await axiosIns.put(`questions/${questionId.value}`, form.value);
        toast.success('Question Updated', {
            position: toast.POSITION.TOP_RIGHT,
            duration: 6000
        });
    } catch (error) {
        console.error('Error:', error.message);
    }
    fetchQuestions();
};

const fetchCategoryNames = async () => {
    try {
        const data = await axiosIns.get('category-faq/names');
        categories.value = data.data.categories;
    } catch (error) {
        console.error('Error:', error.message);
    }
};

const fetchQuestions = async () => {
    loading.value = true
    try {
        const data = await axiosIns.get('questions');
        loading.value = false
        questions.value = data.data.questions;
    } catch (error) {
        console.error('Error:', error.message);
    }
};


const deleteQuestion = async (question) => {
    try {
        const confirmed = confirm("Are you sure you want to delete this question?");

        if (confirmed) {
            const questionId = question.id;

            await axiosIns.delete(`/questions/${questionId}`);

            toast.success('Question Deleted', {
                position: toast.POSITION.TOP_RIGHT,
                duration: 6000
            });
            fetchQuestions()

            }
    } catch (error) {
        console.error('Error deleting question:', error.message);
    }
};


onMounted(() => {
    fetchCategoryNames()
    fetchQuestions()
});
</script>