<template>
  <div class="log-cont">
    <div class="container-fluid log-container">
      <div class="row log-row">
        <div class="col-xs-12 col-md-6 col-lg-6 col-left">
          <div class="left-cont">
            <div class="log-form-box">
              <form @submit.prevent="submitForm">
                <div class="logo-for-responsive">
                    <router-link to="javascript:void(0);" class="log-logo">
                        <img class="img-fluid" src="images/logo-pluspromotions.png" alt="Plus Promotions">
                    </router-link>
                    <router-link to="/login" class="log-logo">
                        <img class="img-fluid" src="images/logo-login.png" alt="SerpPlus">
                    </router-link>
                </div>
                <h1 class="log-title">Forgot Password</h1>
                <p class="log-sub-title">No worries, we've got you covered.</p>
                <div class="form-group">
                  <label>Email<span class="mand-star">*</span></label>
                  <input v-model="form.email" class="form-control" type="email" placeholder="mail@example.com"
                    :class="{ 'is-invalid': emailValidationFailed }" />
                  <div v-if="emailValidationFailed" class="invalid-feedback">Please enter a valid email address.</div>
                </div>
                <Button class="form-common-btn btn btn-primary w-100" :loading="isLoading" type="submit">Send</Button>
              </form>
            </div>
          </div>
        </div>
        <div class="col-xs-12 col-md-6 col-lg-6 col-right">
            <div class="right-cont">
                <a href="javascript:void(0);" class="router-link-active router-link-exact-active log-logo">
                    <img class="img-fluid" src="images/logo-pluspromotions.png" alt="Plus Promotions">
                </a>
                <a href="/login" class="router-link-active router-link-exact-active log-logo">
                    <img class="img-fluid" src="images/logo-login.png" alt="SerpPlus">
                </a>
            </div>
        </div>
      </div>
    </div>
  </div>
</template>
  
<script setup>
import axiosIns from '@/axios';
import { ref } from 'vue';
import { toast } from 'vue3-toastify';
import { useRouter } from 'vue-router';

const isLoading = ref(false)
const router = useRouter();
const form = ref({
  email: ''
});

const emailValidationFailed = ref(false);

const submitForm = async () => {
  emailValidationFailed.value = false;

  if (!isValidEmail(form.value.email)) {
    emailValidationFailed.value = true;
    toast.error('Invalid email format.');
    return;
  }

  try {
    isLoading.value = true
    await axiosIns.post('/reset-password', form.value);
    toast.success('Mail sent', {
      position: toast.POSITION.TOP_RIGHT,
      duration: 3000 // 3000 milliseconds timeout
    });
    isLoading.value = false
    setTimeout(() => {
      router.push('/login');
    }, 3000);
  } catch (error) {
    isLoading.value = false
    console.error('Reset password failed:', error);
    toast.error('Failed to send reset password email.');
  }
}


const isValidEmail = (email) => {
  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  return emailRegex.test(email);
};


</script>
  
<style scoped>
.is-invalid {
  border-color: red;
}

.invalid-feedback {
  color: red;
  font-size: 14px;
}
</style>
  