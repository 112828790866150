<template>
    <div class="app-admin-cont">
        <router-view />
    </div>   

</template>

<script>
export default {
    name: 'App'
}
</script>

<style>
/* #app {
    background-color: var(--bg-white-bg-light);
    height: 100vh;
    width: 100vw;
    line-height: 1.7em;
    font-weight: 400;
    z-index: 0;
}

#app::-webkit-scrollbar {
    width: 5px;
    background-color: #f5f5f5;
    border-radius: 5px;
}

#app::-webkit-scrollbar-thumb {
    border-radius: 5px;
    background-color: #45C9F4;
}

#app::-webkit-scrollbar-track {
    border-radius: 5px;
    background-color: #f5f5f5;
} */
</style>
