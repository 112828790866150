import axiosIns from '../axios';

export default class SerpService {
    checkSerp(formData) {
        return axiosIns
            .post('serp/check-serp', formData)
            .then((response) => {
                return response.data; // You can return the data if needed
            })
            .catch((error) => {
                console.error('Error creating project:', error);
                throw error; // You may want to handle errors in a different way based on your application's needs
            });
    }
    serpsItems(page) {
        return axiosIns
            .get('serp/list-serps', { page: page }) // Send page as a query parameter
            .then((response) => {
                return response.data; // You can return the data if needed
            })
            .catch((error) => {
                console.error('Error listing serps:', error);
                throw error; // You may want to handle errors in a different way based on your application's needs
            });
    }
    addProject(formData) {
        return axiosIns
            .post('projects', formData)
            .then((response) => {
                return response.data; // You can return the data if needed
            })
            .catch((error) => {
                console.error('Error creating project:', error);
                throw error; // You may want to handle errors in a different way based on your application's needs
            });
    }
    UpdateProject(projectId, formData) {
        return axiosIns
            .put(`projects/${projectId}`, formData)
            .then((response) => {
                return response.data; // You can return the data if needed
            })
            .catch((error) => {
                console.error('Error updating project:', error);
                throw error; // You may want to handle errors in a different way based on your application's needs
            });
    }
    listProjects(page,searchQuery) {
        return axiosIns
            .get(`projects?page=${page}&search=${searchQuery.value}`)
            .then((response) => {
                return response.data; // You can return the data if needed
            })
            .catch((error) => {
                console.error(`Error listing projects:`, error);
                throw error; // You may want to handle errors in a different way based on your application's needs
            });
    }
    
}
