<template>
  <div class="log-cont">
    <div class="container-fluid log-container">
      <div class="row log-row">
        <div class="col-xs-12 col-md-6 col-lg-6 col-left">
          <div class="left-cont">
            <div class="log-form-box">
              <form @submit.prevent="submitForm">
                <div class="logo-for-responsive">
                  <router-link to="javascript:void(0);" class="log-logo">
                      <img class="img-fluid" src="images/logo-pluspromotions.png" alt="Plus Promotions">
                  </router-link>
                  <router-link to="/login" class="log-logo">
                    <img class="img-fluid" src="images/logo-login.png" alt="SerpPlus">
                  </router-link>
                </div>
                <h1 class="log-title">Change Password</h1>
                <p class="log-sub-title">Enter your new password for the change!</p>
                <div class="form-group">
                  <label>New Password<span class="mand-star">*</span></label>
                  <input v-model="form.password" class="form-control" type="password" placeholder="Min. 8 characters"
                    :class="{ 'is-invalid': newPasswordValidationFailed }" />
                  <div v-if="newPasswordValidationFailed" class="invalid-feedback">
                    Please enter a valid password (Min. 8 characters).
                  </div>
                </div>
                <div class="form-group">
                  <label>Confirm New Password<span class="mand-star">*</span></label>
                  <input v-model="form.confirmPassword" class="form-control" type="password"
                    placeholder="Min. 8 characters" :class="{ 'is-invalid': confirmPasswordValidationFailed }" />
                  <div v-if="confirmPasswordValidationFailed" class="invalid-feedback">
                    Passwords do not match or are not valid (Min. 8 characters).
                  </div>
                </div>
                <Button class="form-common-btn btn btn-primary w-100" :loading="isLoading" type="submit">Submit</Button>
              </form>
            </div>
          </div>
        </div>
        <div class="col-xs-12 col-md-6 col-lg-6 col-right">
            <div class="right-cont">
                <a href="javascript:void(0);" class="router-link-active router-link-exact-active log-logo">
                    <img class="img-fluid" src="https://serpplus.pluspromotions.co.uk/images/logo-pluspromotions.png" alt="Plus Promotions">
                </a>
                <a href="/login" class="router-link-active router-link-exact-active log-logo">
                    <img class="img-fluid" src="https://serpplus.pluspromotions.co.uk/images/logo-login.png" alt="SerpPlus">
                </a>
            </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import axiosIns from '@/axios';
import { ref, onMounted } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import { toast } from 'vue3-toastify';
import 'vue3-toastify/dist/index.css';

const route = useRoute();
const router = useRouter();  // Fix the typo here
const isLoading = ref(false)

const form = ref({
  password: '',
  confirmPassword: '',
  token: ''
});

const newPasswordValidationFailed = ref(false);
const confirmPasswordValidationFailed = ref(false);

const submitForm = async () => {
  newPasswordValidationFailed.value = false;
  confirmPasswordValidationFailed.value = false;

  // Validate new password
  if (form.value.password.length < 8) {
    newPasswordValidationFailed.value = true;
  }

  // Validate confirm password
  if (form.value.confirmPassword.length < 8 || form.value.confirmPassword !== form.value.password) {
    confirmPasswordValidationFailed.value = true;
  }

  // Check if any validation failed
  if (newPasswordValidationFailed.value || confirmPasswordValidationFailed.value) {
    toast.error('Please enter a valid new password and ensure the confirmation password matches.');
    return;
  }

  try {
    isLoading.value = true
    await axiosIns.post('/update-password', form.value);
    toast.success('Password Changed', {
      position: toast.POSITION.TOP_RIGHT,
      duration: 3000 // 3000 milliseconds timeout
    });
    isLoading.value = false
    setTimeout(() => {
      router.replace('/login');
    }, 3000);
  } catch (error) {
    isLoading.value = false
    console.error('Password change failed:', error);
    toast.error('An error occurred while changing your password. Please try again later.');
  }
};
onMounted(() => {
  form.value.token = route.params.token;
});
</script>

<style>
.is-invalid {
  border-color: red;
}

.invalid-feedback {
  color: red;
  font-size: 14px;
}
</style>