<template>

    <div class="setting-body-cont">

        <div class="admin-body flex-unset">
            <div class="admin-body-box">
                <div class="settings-card">
                    <div class="row setting-row">
                        <div class="setting-col col-xs-12 col-md-12 col-lg-4">
                            <div class="form-group">
                                <label for="amount">Add Amount</label>
                                <div class="input-group align-items-center">
                                    <input type="number" id="amount" class="form-control" v-model="ammount" />
                                    <div class="input-group-append">
                                        <!-- <button class="btn btn-outline-secondary" type="button" @click="addAmount">➕</button> -->
                                        <button class="btn btn-outline-secondary" type="button" @click="addAmount">
                                            <img src="images/icon-add.svg" alt="Add" />
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="setting-col col-xs-12 col-md-6 col-lg-4">
                            <div class="form-group">
                                <label for="select-amount">Select Amount</label>
                                <select id="select-amount" class="form-control" v-model="selectedAmount" @change="calculateCredits">
                                    <option v-for="option in amountOptions" :value="option.id" :key="option.id">{{ option.label }}
                                    </option>
                                </select>
                            </div>
                        </div>
                        <div class="setting-col col-xs-12 col-md-6 col-lg-4">
                            <div class="form-group">
                                <label for="credits">Credits</label>
                                <input type="number" id="credits" class="form-control" v-model="credits" />
                            </div>
                        </div>
                    </div>                
                </div>
            </div>
        </div>

        <div class="admin-bottom-footer">
            <div class="settings-card">
                <div class="row setting-row">
                    <div class="setting-col col-xs-12 col-md-12 col-lg-12">
                        <!-- <div class="form-group d-flex justify-content-center"> -->
                        <div class="form-group mb-0">
                            <button class="admin-common-btn height-50" type="button" @click="updateCredits">Update Credits</button>
                            <button class="admin-common-btn height-50" style="margin-left: 10px; background-color: #FF474C; border: none;"  type="button" @click="deleteAmount">Delete Amount</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    </div>

    <div class="copyright-cont">
        <p class="copyright-text">&copy; 2024 Plus Promotions UK LTD | Registered in UK, Company Number 13871077, VAT Number 438499640</p>
    </div>

</template>

<script setup>
import axiosIns from '@/axios';
import { ref, onMounted } from 'vue';
import { toast } from 'vue3-toastify';
import 'vue3-toastify/dist/index.css';

const selectedAmount = ref(null);
const credits = ref('');
const amountOptions = ref([]);
const ammount = ref('')


const fetchAmountData = async () => {
    try {
        const response = await axiosIns.get('/plans/get-amount');
        amountOptions.value = response.data.data;

        // Sort the amountOptions.value array based on the 'value' property
        amountOptions.value.sort((a, b) => a.value - b.value);
    } catch (error) {
        console.error('Error fetching user data:', error);
    }
};

const deleteAmount = async () => {
    try {
        await axiosIns.delete(`/plans/delete-amount/${selectedAmount.value}`, { credits: credits.value });
        toast.success('Amount deleted!', {
            position: toast.POSITION.TOP_RIGHT,
            duration: 6000
        });
        credits.value = '';
        fetchAmountData();
    } catch (error) {
        toast.error(error.response.data.message, {
            position: toast.POSITION.TOP_RIGHT,
            duration: 6000
        });
    }
}

const updateCredits = async () => {
    try {
        await axiosIns.put(`/plans/update-credits/${selectedAmount.value}`, { credits: credits.value });
        toast.success('Credits Updated!', {
            position: toast.POSITION.TOP_RIGHT,
            duration: 6000
        });
        fetchAmountData();
    } catch (error) {
        toast.error(error.response.data.message, {
            position: toast.POSITION.TOP_RIGHT,
            duration: 6000
        });

    }
};

const calculateCredits = () => {
    if (selectedAmount.value) {
        const selectedOption = amountOptions.value.find((option) => option.id === selectedAmount.value);
        if (selectedOption) {
            credits.value = selectedOption.credits;
        }
    }
};

const addAmount = async () => {
    try {
        await axiosIns.post(`/plans/add-ammount`, { ammount: ammount.value });
        toast.success('Ammount Added!', {
            position: toast.POSITION.TOP_RIGHT,
            duration: 6000
        });
        ammount.value = '';
        fetchAmountData();
    } catch (error) {

        toast.error(error.response.data.message, {
            position: toast.POSITION.TOP_RIGHT,
            duration: 6000
        });
    }
}

onMounted(async () => {
    await fetchAmountData();
});
</script>

<style scoped>
.settings-card {
    /* background-color: #ffffff;
    border: 1px solid #ccc;
    border-radius: 5px;
    padding: 10px;
    width: 300px; */
}

.card-title {
    /* text-align: center; */
}
</style>