import axiosIns from '../axios';

export default class CountryService {
    getCountries() {
        return fetch('demo/data/countries.json')
            .then((res) => res.json())
            .then((d) => d.data);
    }

    getCityAndState(location_code) {
        return axiosIns
            .get(`serp/list-cities/${location_code}`)
            .then((response) => {
                return response.data; // You can return the data if needed
            })
            .catch((error) => {
                console.error('Error creating project:', error);
                throw error; // You may want to handle errors in a different way based on your application's needs
            });
    }
}
